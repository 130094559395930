import React from 'react';

export const PureComponent = 'PureComponent' in React ? React.PureComponent : React.Component;

export default (Component, areEqualCheck) => {

  if ('memo' in React) {
    return React.memo(Component, areEqualCheck);
  }

  if ('PureComponent' in React) {
    class Memoized extends React.PureComponent {
      shouldComponentUpdate(nextProps, nextState, ...params) {
        return typeof areEqualCheck === 'function'
          ? !areEqualCheck(this.props, nextProps)
          : super.shouldComponentUpdate(nextProps, nextState, ...params);
      }

      render() {
        return React.createElement(Component, {...this.props});
      }
    }
    return Memoized;
  }

  return Component;
}
