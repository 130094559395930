const KB_FOCUS_ACTIVE = 'data-focus-keyboard-active';

const removeKeyboardFocusActive = () => {
  if (document.body.hasAttribute(KB_FOCUS_ACTIVE)) {
    document.body.removeAttribute(KB_FOCUS_ACTIVE);
  }
};

const addKeyboardFocusActive = e => {
  const TAB = 9;
  if (e.keyCode === TAB) {
    document.body.setAttribute(KB_FOCUS_ACTIVE, true);
  }
};

export const attach = () => {
  document.addEventListener('click', removeKeyboardFocusActive);
  document.addEventListener('mousedown', removeKeyboardFocusActive);
  document.addEventListener('mouseup', removeKeyboardFocusActive);
  document.addEventListener('pointerdown', removeKeyboardFocusActive);
  document.addEventListener('pointerup', removeKeyboardFocusActive);
  document.addEventListener('touchstart', removeKeyboardFocusActive);
  document.addEventListener('touchend', removeKeyboardFocusActive);

  document.body.addEventListener('keydown', addKeyboardFocusActive);
  document.body.addEventListener('keyup', addKeyboardFocusActive);
  document.body.addEventListener('keypress', addKeyboardFocusActive);
};

export const dettach = () => {
  document.removeEventListener('click', removeKeyboardFocusActive);
  document.removeEventListener('mousedown', removeKeyboardFocusActive);
  document.removeEventListener('mouseup', removeKeyboardFocusActive);
  document.removeEventListener('pointerdown', removeKeyboardFocusActive);
  document.removeEventListener('pointerup', removeKeyboardFocusActive);
  document.removeEventListener('touchstart', removeKeyboardFocusActive);
  document.removeEventListener('touchend', removeKeyboardFocusActive);

  document.body.removeEventListener('keydown', addKeyboardFocusActive);
  document.body.removeEventListener('keyup', addKeyboardFocusActive);
  document.body.removeEventListener('keypress', addKeyboardFocusActive);
};
