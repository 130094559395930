import React from 'react';
import CaptionPrimary from 'shared/ui/atoms/caption/primary';

import styles from './label.scss';

const DrawerHeaderLabel = ({children}) => (
  <CaptionPrimary neutral className={styles.label} data-role="drawer-header-label">
    {children}
  </CaptionPrimary>
);

export default DrawerHeaderLabel;
