import color from '../../../css/functions/color.scss';

export default {
  'color-input-default': color.grey500,
  'color-input-muted': color.grey600,
  'color-input-label-optional': color.grey600,
  'color-input-placeholder': color.grey600,
  'color-border-default': color.grey500,
  'color-dismiss-default': color.grey600,
  'color-tab-default': color.grey600,
  'color-input-helper-default': color.grey600
};
