import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';

/**
 * A helper that can build GET like URLs given a base URL
 * a path and optionally one or many parameters.
 *
 * Example usage:
 * @example
 * import url from 'helpers/url';
 *
 * url
 *   .withBaseURL('https://url.test')
 *   .withPath('path/to/api')
 *   .withParameters({
 *     fruit: 'banana',
 *     baskets: 3
 *   })
 *   .build();
 */
export default {
  withBaseURL: (base = '') => ({
    withPath: path => {
      let parameters = {};
      const _ = {
        withParameter: (key, value) => {
          parameters[key] = value;
          return _;
        },
        withParameters: p => {
          parameters = {...parameters, ...p};
          return _;
        },
        build: () =>
          `${base}/${path || ''}?${compact(
            Object.entries(pickBy(parameters, v => !!v))
              .map(([k, v]) => [k, v && encodeURIComponent(v)])
              .map(p => p && p.join('='))
          ).join('&')}`
      };
      return _;
    }
  })
};
