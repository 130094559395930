/**
 * Calculates and returns the absolute size of an Element.
 *
 * With absolute, we mean that the width and height take margins into account.
 *
 * @param {HTMLElement} el
 * @returns {[number, number]} `[height, width]`
 */
const getAbsoluteSize = el => {
  const styles = window.getComputedStyle(el);

  const marginTop = parseFloat(styles.marginTop) || 0;
  const marginBottom = parseFloat(styles.marginBottom) || 0;
  const marginRight = parseFloat(styles.marginRight) || 0;
  const marginLeft = parseFloat(styles.marginLeft) || 0;

  const height = Math.ceil(el.offsetHeight + marginTop + marginBottom);
  const width = Math.ceil(el.offsetWidth + marginRight + marginLeft);

  return [height, width];
};

export default getAbsoluteSize;
