export const resolverSymbol = Symbol('DefaultTranslationResolver');

const setDefaultDynamicTexts = ({...locales}, {...defaults} = {}) => {
  const result = {};
  Object.keys(locales).forEach(key => {
    const _default = defaults[key];
    const locale = locales[key];
    if (_default instanceof Function) {
      // eslint-disable-next-line no-new-wrappers
      result[key] = new String(locale);
      result[key][resolverSymbol] = _default;
      return;
    }

    if (typeof locale === 'string') {
      result[key] = locale;
      return;
    }

    result[key] = setDefaultDynamicTexts(locale, _default);
  });

  return result;
};

export default setDefaultDynamicTexts;
