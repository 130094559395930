import React, {forwardRef, PropsWithChildren} from 'react';
import clsx from 'clsx';
import BaseButton from '../base';
import symbols from 'shared/ui/symbols';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import {FlavoredButtonProps} from 'shared/ui/atoms/button/base';

import styles from './ghost.scss';

const ButtonGhost: React.ForwardRefExoticComponent<PropsWithChildren<FlavoredButtonProps>> = forwardRef(
  ({neutral, caution, danger, muted, informational, ai, ...props}, ref) => {
    const classNames = mapPropsToStyleNames([
      {neutral, caution, danger, white: muted, informational, ai, default: 'default'}
    ])
      .split(' ')
      .map(c => styles[c])
      .join(' ');

    return <BaseButton {...props} ref={ref} className={clsx(classNames, props.className)} />;
  }
);

ButtonGhost.displayName = 'Button.Ghost';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ButtonGhost[symbols.Button.Ghost] = true;

export default ButtonGhost;
