import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../css/functions/color.scss';
import styles from './styles.scss'; // eslint-disable-line no-unused-vars

export const withColor =
  Component =>
  ({color, background, style = {}, ...props}) => {
    if (color && colors[color]) {
      style.color = colors[color];
    }

    if (background && colors[background]) {
      style.backgroundColor = colors[background];
    }

    return <Component {...props} style={style} />;
  };

withColor.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string
};

export const withTextProps =
  Component =>
  ({underline, italic, strong, dangerouslySetInnerHTML, children, ...props}) => {
    let processedChildren = children;
    let hasAtLeastOneSemantic = false;

    if (strong) {
      processedChildren = (
        <strong
          className={styles.strong}
          children={processedChildren}
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        />
      );
      hasAtLeastOneSemantic = true;
    }

    if (italic) {
      processedChildren = (
        <i
          className={styles.italic}
          children={processedChildren}
          dangerouslySetInnerHTML={(!hasAtLeastOneSemantic && dangerouslySetInnerHTML) || undefined}
        />
      );
      hasAtLeastOneSemantic = true;
    }

    if (underline) {
      processedChildren = (
        <u
          className={styles.underline}
          children={processedChildren}
          dangerouslySetInnerHTML={(!hasAtLeastOneSemantic && dangerouslySetInnerHTML) || undefined}
        />
      );
      hasAtLeastOneSemantic = true;
    }

    const WrappedComponent = (
      <Component
        {...props}
        children={processedChildren}
        dangerouslySetInnerHTML={(!hasAtLeastOneSemantic && dangerouslySetInnerHTML) || undefined}
      />
    );

    return WrappedComponent;
  };

withTextProps.propTypes = {
  strong: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool
};

export default Component => {
  const Wrapped = withTextProps(withColor(Component));
  Wrapped.propTypes = {
    ...withTextProps.propTypes,
    ...withColor.propTypes,
    ...Component.propTypes
  };
  return Wrapped;
};
