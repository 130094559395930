import Color from 'color';

export default function darken(color, amount, rgb = false) {
  const hslColor = Color(color);

  const lightness = hslColor.lightness();

  const lightenedColor = hslColor.lightness(lightness - amount);

  return rgb
    ? lightenedColor
      .rgb()
      .round()
      .array()
    : lightenedColor.hex();
}
