import React from 'react';
import symbols from 'shared/ui/symbols';
import TextBody from 'shared/ui/atoms/text/body';
import {DIALOG_ROLES} from '../constants';

const DialogContent = ({children, ...props}) => {
  return (
    <div {...props} data-role={DIALOG_ROLES.Content}>
      {typeof children === 'string' ? <TextBody emphasis>{children}</TextBody> : children}
    </div>
  );
};

DialogContent.displayName = 'Dialog.Content';

DialogContent[symbols.Dialog.Content] = true;

export default DialogContent;
