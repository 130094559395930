import React from 'react';
import symbols from 'shared/ui/symbols';
import SecondaryHeading from 'shared/ui/atoms/heading/secondary';
import TertiaryHeading from 'shared/ui/atoms/heading/tertiary';
import {DIALOG_ROLES} from '../constants';

export const TitleContainer = ({children, isMobile, ...props}) => {
  return isMobile ? (
    <TertiaryHeading as="h2" strong emphasis {...props}>
      {children}
    </TertiaryHeading>
  ) : (
    <SecondaryHeading strong {...props}>
      {children}
    </SecondaryHeading>
  );
};

const DialogTitle = ({children, mobile, ...props}) => (
  <NakedDialogTitle {...props}>
    <TitleContainer isMobile={mobile}>{children}</TitleContainer>
  </NakedDialogTitle>
);

DialogTitle.displayName = 'Dialog.Title';

DialogTitle[symbols.Dialog.Title] = true;

const NakedDialogTitle = ({children, ...props}) => (
  <div {...props} data-role={DIALOG_ROLES.Title}>
    {children}
  </div>
);

NakedDialogTitle[symbols.Dialog.Title] = true;

export {NakedDialogTitle};

export default DialogTitle;
