import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';
import styles from '../animated.scss';

const Icon = ({size = 16, responsive, animated, ...props}) => {
  return (
    (<BaseSvg size={size} responsive={responsive} {...props}>
      <Graph {...props} className={clsx({
        [styles.animated]: animated
      }, props.className)}>
        <Graph {...props} className={clsx(styles.fill, props.className)} as="circle" cx="8" cy="8" r="7" fill />
        <Graph
          {...props}
          className={clsx(styles["circle-stroke"], props.className)}
          as="circle"
          cx="8"
          cy="8"
          r="7"
          strokeWidth="2"
          strokeDasharray={`${Math.PI * size}`} // 2 * PI * r [r=size/2]
          strokeDashoffset={`${2 * Math.PI * size}`}
        />
        <path
          className={styles["line-stroke"]}
          d="M8,3.8 L8,9"
          stroke="#fff"
          strokeWidth="1.9"
          strokeDasharray="100% 100%"
          strokeDashoffset="200%"
        />
        <circle className={styles["delayed-fill"]} cx="8" cy="11" r="1" stroke="none" fill="#fff" strokeWidth="1" />
      </Graph>
    </BaseSvg>)
  );
};

Icon.displayName = 'Icon.Exclamation';

setSymbol(Icon);

Icon.propTypes = {
  ...BaseSvg.propTypes,
  /** Controls whether it should have animation. */
  animated: PropTypes.bool
};

export default Icon;
