import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import propsFilter from 'shared/ui/helpers/propsFilter';
import styles from './styles.scss';

class BaseLoader extends React.PureComponent {
  state = {
    minLoaded: true
  };

  componentDidMount() {
    const {loading} = this.props;

    if (loading === true) {
      this.addTimeout();
    }
  }

  componentDidUpdate(prevProps) {
    const {loading} = this.props;

    if (loading === true && prevProps.loading === false) {
      this.addTimeout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  addTimeout() {
    const {minLoadingTime} = this.props;

    if (!minLoadingTime) {
      return;
    }

    this.setState({minLoaded: false}, () => {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.setState({minLoaded: true});
      }, minLoadingTime);
    });
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const {loader, loading, backdrop, as: Kind = 'section', children, minLoadingTime, ...props} = this.props;

    if (!children) {
      const allowedProps = propsFilter(props)
        .dataAttributes()
        .styles()
        .getFiltered();

      return React.cloneElement(loader, allowedProps);
    }

    const isBusy = !this.state.minLoaded || loading;

    return (
      (<Kind
          {...props}
          className={clsx({
            [styles.container]: true,
            [styles.backdrop]: backdrop
          }, props.className)}
          aria-live="polite"
          aria-busy={isBusy}
          data-role="loader-container"
        >
        {children}
        <div className={styles["loader-container"]}>{loader}</div>
      </Kind>)
    );
  }
}

BaseLoader.propTypes = {
  /** The element used as loader. */
  loader: PropTypes.element,
  /** Controls whether loading is taking place or not. */
  loading: PropTypes.bool,
  /** Controls whether backdrop should be placed behind the loader. */
  backdrop: PropTypes.bool,
  /** The minimun loading time. Before this we cannot remove the loader. */
  minLoadingTime: PropTypes.number,
  /** Morphs container into another Component or element. Defaults to `section` */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default BaseLoader;
