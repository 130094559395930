import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeNumberLocaleUtils from 'shared/ui/helpers/numberLocaleUtils';
import styles from './styles.scss';

const allowLocalizedCommaDecimalOnNumber =
  (onKeyDown = () => {}) =>
  e => {
    const commaDecimal = ',';
    const localeDecimalMark = makeNumberLocaleUtils().getDecimalMark();
    if (e.key === commaDecimal && commaDecimal !== localeDecimalMark) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    onKeyDown(e);
  };

const getWithAriaProperties = props => ({
  'aria-disabled': props.disabled || undefined,
  'aria-readonly': props.readOnly,
  'aria-required': props.required,
  ...props
});

const Base = React.forwardRef(({as: ElementKind = 'input', ...props}, ref) => (
  <ElementKind
    {...getWithAriaProperties(props)}
    className={clsx(styles.input, props.className)}
    dir="auto"
    ref={ref}
    onKeyDown={props.type === 'number' ? allowLocalizedCommaDecimalOnNumber(props.onKeyDown) : props.onKeyDown}
    onWheel={
      props.type === 'number'
        ? event => {
            if (props.onWheel) {
              props.onWheel(event);
            }
            event.currentTarget.blur();
          }
        : props.onWheel
    }
  />
));

const BaseWithMaskedInput = ({mask: MaskedInput, readOnly, disabled, ...props}) => {
  const htmlRef = el => props.onLoad && props.onLoad(el);
  return MaskedInput && !readOnly && !disabled ? (
    <Base
      {...props}
      readOnly={readOnly}
      disabled={disabled}
      as={MaskedInput.type}
      options={{...MaskedInput.props}}
      htmlRef={htmlRef}
    />
  ) : (
    <Base {...props} readOnly={readOnly} disabled={disabled} ref={htmlRef} />
  );
};

BaseWithMaskedInput.propTypes = {
  /** The masked input component. Create a mask by importing `shared/ui/atoms/input/mask` */
  mask: PropTypes.element,
  /** The value of input. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
  /** Triggered when input changes value. */
  onChange: PropTypes.func,
  /** The type of the input. */
  type: PropTypes.string,
  /** The name of the input. */
  name: PropTypes.string,
  /** The placeholder of the input. */
  placeholder: PropTypes.string,
  /** Render the input as disabled. */
  disabled: PropTypes.bool,
  /** Render the input as read-only. */
  readOnly: PropTypes.bool,
  /** Render the input as required. */
  required: PropTypes.bool,
  /** The function that will be used as callback ref for the input. */
  onLoad: PropTypes.func
};

BaseWithMaskedInput.displayName = 'Input.Base';

export default BaseWithMaskedInput;
