import PropTypes from 'prop-types';

/**
 * PropTypes shared by all animations
 */
export default {
  /** The component to transform to as animated */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /** Duration of the animation in milliseconds. Default 300ms */
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Duration of animation delay. Default 0ms */
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Callback to execute code once the animation is completed */
  handleCompleted: PropTypes.func,
  /** When true, may start the animation. Otherwise, the animation starts with `startAnimate` event. */
  animate: PropTypes.bool,
  /** The event that triggers animation to start. eg. `mouseEnter` */
  startAnimate: PropTypes.string,
  /** The event that stops animation. eg. `mouseOut` */
  stopAnimate: PropTypes.string
};
