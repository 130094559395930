import React from 'react';
import {meta} from 'candidate/config';
import {account, NO_ACCOUNT} from 'candidate/shared/paths';
import Account from './account';

const __account = (pathname, subdomain = meta.subdomain) => {
  const _account = account(pathname);
  if (_account && _account !== NO_ACCOUNT) {
    return _account;
  }
  return subdomain;
};

export default class AccountProvider extends React.Component {
  render() {
    const _account = __account(this.props.pathname, this.props.subdomain);

    return (
      <Account.Provider value={_account}>
        {this.props.children instanceof Function ? this.props.children(_account) : this.props.children || null}
      </Account.Provider>
    );
  }
}
