import {createCookieString} from 'shared/helpers/cookie';
import {CAT_PREF_COOKIE_NAME, OPT_OUT_COOKIE_NAME} from './constants';
import type {Selections} from './components/settings';

export const getCookie = (name: string) => {
  const matchRegex = new RegExp('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return matchRegex.exec(document.cookie)?.pop() || '';
};

export const getOptOutCookieValue = () => getCookie(OPT_OUT_COOKIE_NAME);

export const setOptOutCookieValue = (value: string) => {
  document.cookie = createCookieString(OPT_OUT_COOKIE_NAME, value);
};

const createCatPrefCookieString = (value: Selections) => {
  return value.map((v, index) => `${index + 1}:${v ? 'true' : 'false'}`).join(',');
};

export const setCatPrefCookieValue = (value: Selections) => {
  document.cookie = createCookieString(CAT_PREF_COOKIE_NAME, createCatPrefCookieString(value));
};
