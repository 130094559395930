export const getMetaVariable = (name, _window = window) => {
  const metaField =
    _window && _window.document && _window.document.head && _window.document.head.querySelector(`meta[name="${name}"]`);

  const content = metaField && metaField.content;

  try {
    return JSON.parse(content);
  } catch (e) {
    return content;
  }
};

export const getMeta = (_window = window) =>
  Object.freeze(
    Object.assign(Object.create(null), {
      subdomain: getMetaVariable('subdomain', _window),
      ga4: getMetaVariable('ga4', _window),
      themeColor: getMetaVariable('theme-color', _window),
      hasCustomDomain: getMetaVariable('hasCustomDomain', _window),
      domain: getMetaVariable('domain', _window),
      job: getMetaVariable('job', _window),
      version: getMetaVariable('version')
    })
  );

export default getMeta();
