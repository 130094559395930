import url from 'helpers/url';

const BASE_URL = 'https://www.googletagmanager.com';

const _ga4 = (id, options = {}) =>
  new Promise(resolve => {
    const parameters = {
      id,
      ...(options.dataLayerName && {
        l: options.dataLayerName
      })
    };

    const script = window.document.createElement('script');

    script.src = url.withBaseURL(BASE_URL).withPath('gtag/js').withParameters(parameters).build();
    script.async = true;
    window.document.head.appendChild(script);

    const dataLayerName = options.dataLayerName || 'dataLayer';

    window[dataLayerName] = window[dataLayerName] || [];

    window.gtag4 = function () {
      window[dataLayerName].push(arguments);
    };

    window.gtag4('js', new Date());
    window.gtag4('config', id, options);

    resolve();
  });

/**
 * Factory method for constructing a ga client for analytics via Google Analytics 4.
 *
 * @param {string} id - The Measurement id of the project.
 */
export default (id, ...params) => {
  if (!id) {
    return;
  }

  const ga4 = _ga4(id, ...params);

  return {
    /**
     * Emits a custom Google Analytics event. Equivalent to gtag('event', action, params);
     *
     * @param {string} category - The event category, e.g. 'video'.
     * @param {string} action -The event action, e.g. 'view'.
     * @param {string} label - The event label, e.g. 'Nyan Cat'.
     */
    event: (category, action, label, nonInteractive = false) => ({
      send: () =>
        ga4.then(
          () =>
            window &&
            window.gtag4 &&
            window.gtag4('event', action, {
              event_category: category,
              event_label: label,
              value: 0,
              non_interaction: nonInteractive
            })
        )
    }),
    /**
     * Emits a Google Analytics pageview event. Equivalent to gtag('config', id, {'page_path': path});
     *
     * @param {string} path - The pageview path that was visited, e.g. '/' or '/my/application'.
     */
    pageview: path => ({
      send: () => ga4.then(() => window && window.gtag4 && window.gtag4('event', 'page_view', {page_path: path}))
    })
  };
};
