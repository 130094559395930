import getAbsoluteSize from './getAbsoluteSize';
import getBoundingBox from './getBoundingBox';
import getContainingBlockElement from './getContainingBlockElement';
import getDocumentHeight from './getDocumentHeight';

import {MOBILE_MARGIN, POSITIONS} from '../constants';

const checkPositionFit = ({target, element, positionVertical}) => {
  if (!element || !target) {
    return false;
  }

  const {bottom: targetBottom, top: targetTop} = getBoundingBox(target, {
    container: getContainingBlockElement(element)
  });

  const [dropdownHeight] = getAbsoluteSize(element);

  if (positionVertical === POSITIONS.TOP) {
    return targetTop - dropdownHeight < MOBILE_MARGIN;
  } else if (positionVertical === POSITIONS.BOTTOM) {
    return targetBottom + dropdownHeight >= getDocumentHeight() - MOBILE_MARGIN;
  }

  return false;
};

export default checkPositionFit;
