import React from 'react';
import i18n from 'candidate/i18n';
import Generic from './generic';
import {makeNotFoundSvg, makeOfflineSvg, makeServerErrorSvg} from './svg';

export const NotFoundError = () => (
  <Generic
    Icon={makeNotFoundSvg()}
    message={i18n.t('errors.notFound.message')}
    title={i18n.t('errors.notFound.title')}
  />
);

export const OfflineError = () => (
  <Generic Icon={makeOfflineSvg()} message={i18n.t('errors.offline.message')} title={i18n.t('errors.offline.title')} />
);

export const ServerError = () => (
  <Generic
    Icon={makeServerErrorSvg()}
    message={i18n.t('errors.server.message')}
    title={i18n.t('errors.server.title')}
  />
);

export const UnknownError = () => (
  <Generic
    Icon={makeServerErrorSvg()}
    message={i18n.t('errors.unknown.message')}
    title={i18n.t('errors.unknown.title')}
  />
);
