const sampleNumber = 10000.1;

const toLocaleStringSupportsLocales = () => {
  const number = 0;
  try {
    number.toLocaleString('i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}

const numberLocaleUtils = (locale = window.navigator.language, options = {}) => {
  const defaultFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1
  };

  const localizeOptions = Object.assign({}, defaultFormatOptions, options);

  return {
    getLocalizedString(number, opts) {
      if (isNaN(number) || typeof number !== 'number') {
        throw new Error('The argument passed is not number. Can not be localized.');
      }

      return toLocaleStringSupportsLocales() ? number.toLocaleString(locale, Object.assign({}, localizeOptions, opts)) : number.toLocaleString();
    },

    getDecimalMark() {
      return this.getLocalizedString(sampleNumber, {maximumFractionDigits: 1}).split(/\d+/)[2];
    },

    getThousandsMark() {
      return this.getLocalizedString(sampleNumber).split(/\d+/)[1];
    }
  };
};

export default numberLocaleUtils;
