import {useLayoutEffect} from 'react';
import Hammer from 'hammerjs';

const HAMMER_PAN_EVENTS = 'panup pandown panstart panend';

export default function useDrawerSwipeDown({dialogElement, enabled, onSwipeDown}) {
  useLayoutEffect(() => {
    if (!enabled || !dialogElement) {
      return;
    }

    let pristineTransitionValue;

    const drawerHeaderSeparatorEl = dialogElement.querySelector('[data-role="drawer-header-separator"]');

    if (!drawerHeaderSeparatorEl) {
      return;
    }

    const mc = new Hammer(drawerHeaderSeparatorEl);
    mc.get('pan').set({direction: Hammer.DIRECTION_VERTICAL});

    mc.on(HAMMER_PAN_EVENTS, function (ev) {
      if (ev.type === 'panstart') {
        pristineTransitionValue = window.getComputedStyle(dialogElement).transition;
      }

      if (ev.type === 'panend') {
        dialogElement.style.transition = pristineTransitionValue;

        const halfDialogHeight = dialogElement.clientHeight / 2;
        if (ev.distance > halfDialogHeight) {
          dialogElement.style.transform = 'translateY(100%)';
          onSwipeDown?.();
          return;
        }

        dialogElement.style.transform = 'translateY(0%)';
        return;
      }

      if (['panup', 'pandown'].includes(ev.type) && ev.deltaY > 0) {
        dialogElement.style.transition = 'unset';
        dialogElement.style.transform = `translateY(${ev.distance}px)`;
      }
    });

    return () => {
      mc.off(HAMMER_PAN_EVENTS);
    };
  }, [dialogElement, enabled, onSwipeDown]);
}
