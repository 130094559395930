import React from 'react';
import {Device, useDevice} from 'shared/ui/providers/responsive';

const withResponsiveness = Component => {
  return ({mobile, ...props}) => {
    const [device] = useDevice();

    const isMobile = mobile !== undefined ? mobile : device === Device.Mobile;

    return <Component {...props} mobile={isMobile} />;
  };
};

export default withResponsiveness;
