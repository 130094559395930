import React, {forwardRef} from 'react';
import clsx from 'clsx';
import BaseButton from '../base';
import propTypes from '../base/proptypes/flavored';
import symbols from 'shared/ui/symbols';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import styles from './secondary.scss';

const ButtonSecondary = forwardRef(({neutral, caution, danger, muted, informational, ai, ...props}, ref) => {
  const classNames = mapPropsToStyleNames([
    {neutral, caution, danger, white: muted, informational, ai, default: 'default'}
  ])
    .split(' ')
    .map(name => styles[name])
    .join(' ');

  return <BaseButton {...props} ref={ref} className={clsx(classNames, props.className)} />;
});

ButtonSecondary.displayName = 'Button.Secondary';

ButtonSecondary[symbols.Button.Secondary] = true;

ButtonSecondary.propTypes = {
  ...propTypes
};

export default ButtonSecondary;
