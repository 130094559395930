import React from 'react';
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import getRealChildProps from 'shared/ui/helpers/getRealChildProps';

let TAB_KEYCODE;
let ENTER_KEYCODE;
let SPACE_KEYCODE;
let ESC_KEYCODE;
let UP_KEYCODE;
let RIGHT_KEYCODE;
let DOWN_KEYCODE;
let LEFT_KEYCODE;
const DOWN_KEYCODE_IE = 'Down';
const UP_KEYCODE_IE = 'Up';
const RIGHT_KEYCODE_IE = 'Right';
const LEFT_KEYCODE_IE = 'Left';
const ESC_KEYCODE_IE = 'Esc';
const SPACE_KEYCODE_IE = 'Spacebar';

const setKeyCodes = () => {
  if (typeof KeyboardEvent === 'function') {
    TAB_KEYCODE = 9;
    ESC_KEYCODE = 27;
    ENTER_KEYCODE = 13;
    UP_KEYCODE = 38;
    RIGHT_KEYCODE = 39;
    DOWN_KEYCODE = 40;
    LEFT_KEYCODE = 37;
    SPACE_KEYCODE = 32;
  } else {
    TAB_KEYCODE = 'Tab';
    ESC_KEYCODE = 'Escape';
    ENTER_KEYCODE = 'Enter';
    SPACE_KEYCODE = 'Space';
    DOWN_KEYCODE = 'ArrowDown';
    UP_KEYCODE = 'ArrowUp';
    RIGHT_KEYCODE = 'ArrowRight';
    LEFT_KEYCODE = 'ArrowLeft';
  }
};
setKeyCodes();

const DIRECTIONS = {
  UP: 'up',
  RIGHT: 'right',
  DOWN: 'down',
  LEFT: 'left'
};

const directionsMap = {
  [UP_KEYCODE]: DIRECTIONS.UP,
  [RIGHT_KEYCODE]: DIRECTIONS.RIGHT,
  [DOWN_KEYCODE]: DIRECTIONS.DOWN,
  [LEFT_KEYCODE]: DIRECTIONS.LEFT
};

const defaultHandlers = {
  /* eslint-disable no-unused-vars */
  onKeyDown: () => {},
  handleEnterPressed: event => {},
  handleTabPressed: (event, {shiftKey}) => {},
  handleEscapePressed: event => {},
  handleArrowsPressed: (event, {direction}) => {},
  handleKeyPressed: event => {},
  handleSpacePressed: event => {}
  /* eslint-enable no-unused-vars */
};

const KeyboardHandler = ({children, ...props}) => {
  const reactChildProps = getRealChildProps(children, {
    onKeyDown: {
      default: defaultHandlers.onKeyDown
    }
  });

  const keyDownPreviousHandler = props.onKeyDown || reactChildProps.onKeyDown;

  const downProps = {
    onKeyDown: event => {
      const handlers = {...defaultHandlers, ...props};

      keyDownPreviousHandler(event);

      const eventKeyLookup = typeof KeyboardEvent === 'function' ? event.keyCode : event.key;

      switch (eventKeyLookup) {
        case TAB_KEYCODE:
          handlers.handleTabPressed(event, {
            shiftKey: event.shiftKey
          });
          break;

        case ESC_KEYCODE:
        case ESC_KEYCODE_IE:
          handlers.handleEscapePressed(event);
          break;

        case ENTER_KEYCODE:
          handlers.handleEnterPressed(event);
          break;

        case SPACE_KEYCODE:
        case SPACE_KEYCODE_IE:
          handlers.handleSpacePressed(event);
          break;

        case DOWN_KEYCODE:
        case RIGHT_KEYCODE:
        case UP_KEYCODE:
        case LEFT_KEYCODE:
          handlers.handleArrowsPressed(event, {
            direction: directionsMap[eventKeyLookup]
          });
          break;

        case DOWN_KEYCODE_IE:
        case RIGHT_KEYCODE_IE:
        case UP_KEYCODE_IE:
        case LEFT_KEYCODE_IE:
          handlers.handleArrowsPressed(event, {
            direction: directionsMap[`Arrow${eventKeyLookup}`]
          });
          break;

        default:
          handlers.handleKeyPressed(event);
      }
    }
  };

  const $child = React.cloneElement(React.Children.only(children), downProps);

  return $child;
};

KeyboardHandler[symbols.Behavior] = true;

KeyboardHandler.defaultHandlers = defaultHandlers;

KeyboardHandler.constants = {
  DIRECTIONS
};

KeyboardHandler.propTypes = {
  handleEnterPressed: PropTypes.func,
  handleSpacePressed: PropTypes.func,
  handleTabPressed: PropTypes.func,
  handleEscapePressed: PropTypes.func,
  handleArrowsPressed: PropTypes.func,
  handleKeyPressed: PropTypes.func
};

export default KeyboardHandler;
