import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import styles from './styles.scss';

const IllustratedContainer = React.forwardRef(({as: Kind = 'div', children, ...props}, ref) => {
  const leftIcons = [];
  const rightIcons = [];
  const sequentialContent = [];
  React.Children.forEach(children, (child, key) => {
    if (!React.isValidElement(child)) {
      if (typeof child === 'string') {
        return sequentialContent.push(<span key={key}>{child}</span>);
      }
      return;
    }

    const {type: IconKind} = child;
    if (IconKind && IconKind[symbols.Icon]) {
      const {left, right, ...childProps} = child.props;
      const iconRender = <IconKind key={key} {...childProps} className={clsx(styles.icon, childProps.className)} />;

      if (left) {
        return leftIcons.push(iconRender);
      }
      if (right) {
        return rightIcons.push(iconRender);
      }
      return sequentialContent.push(iconRender);
    }

    return sequentialContent.push(React.cloneElement(child, {key}));
  });

  return (
    <Kind ref={ref} {...props}>
      <div className={styles['illustrated-container']}>
        {leftIcons}
        {sequentialContent}
        {rightIcons}
      </div>
    </Kind>
  );
});

IllustratedContainer.displayName = 'Icon.IllustratedContainer';

IllustratedContainer.propTypes = {
  /** Applies icon container logic to the passed Component. Defaults to `div`, pass any React Component */
  as: PropTypes.elementType,
  /**
   * Can be any react element or elements
   * When Icons passed they get processed appropriately in order to show them in the container
   * Positioning (left|right) of the Icons depends on their position in children as they are being passed.
   * e.g. Two children where the first is the icon and the second some text, then since icon comes before
   * text, it gets positioned left of the container. You can force an icons position by passing the
   * properties `left` or `right` to each individual icon.
   * @example
   * <IllustratedContainer as='button'>
   *  <Icon.Eye/> //Left positioned icon
   *  Show More // Content follows
   * </IllustratedContainer>
   *
   * @example
   * <IllustratedContainer as='button'>
   *  <Icon.Eye right/> //Right positioned icon
   *  Show More // Content precedes
   * </IllustratedContainer>
   * */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default IllustratedContainer;
