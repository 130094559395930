export default Object.freeze({
  Actions: {
    FilteredByDepartment: 'FilteredByDepartment',
    FilteredByLocation: 'FilteredByLocation',
    FilteredByType: 'FilteredByType',
    FilteredByWorkplace: 'FilteredByWorkplace',
    FilteredRemoteJobs: 'FilteredRemoteJobs',
    IndeedButton: 'IndeedButton',
    LinkedinButton: 'LinkedinButton',
    QuickApply: 'QuickApply',
    SearchedJobs: 'SearchedJob',
    ServerValidationError: 'ServerValidationError',
    SocialShare: 'SocialShare',
    Submit: 'Submit',
    ViewedLocationSuggestion: 'ViewedLocationSuggestion'
  },
  Categories: {
    ApplicationForm: 'ApplicationForm',
    Job: 'Job',
    ValidationError: 'ValidationError'
  }
});
