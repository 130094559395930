import PropTypes from 'prop-types';
import buttonPropTypes from './button';

export default {
  /** Coloring the button as a caution button */
  caution: PropTypes.bool,
  /** Coloring the button as a danger button */
  danger: PropTypes.bool,
  /** Coloring the button as a neutral button */
  neutral: PropTypes.bool,
  ...buttonPropTypes
};
