import {Children, isValidElement} from 'react';
import symbols from 'shared/ui/symbols';

/**
 * Gets the underneath react child behind a series of behaviors.
 * Since this there is a loop checking the nested child of each child
 * there is a limit on the number of nestings befor throwing an error.
 * @param  {Object} wrappedChild The child which may also be a behavior wrap
 * @throws {Error} Too many nested behaviors without finding a child
 * @throws {Error} No valid nested react child
 * @returns {Object} The react child underneath behavior wrappers
 */
const getRealReactChild = wrappedChild => {
  const nestIterateLimit = 10;
  let child = Children.only(wrappedChild);
  let iteration = 0;

  while (child.type[symbols.Behavior] || iteration === nestIterateLimit) {
    iteration = iteration + 1;
    child = Children.only(child.props.children);
  }

  if (iteration === nestIterateLimit) {
    throw new Error(
      [
        'Too many nested behaviors before meeting a React child.',
        'That nested depth of behaviors is not expected.',
        'Current depth limit is set to ',
        nestIterateLimit
      ].join(' ')
    );
  }

  if (!isValidElement(child)) {
    throw new Error('Could not find valid nested React element.');
  }

  return child;
};

export default getRealReactChild;
