import Container from './container';
import Actions from './actions';
import Title from './title';
import Content from './content';

export default {
  Container,
  Actions,
  Title,
  Content
};
