import React from 'react';
import clsx from 'clsx';
import TextBase from '../../text/base';
import {SkeletonHeading} from 'shared/ui/atoms/skeleton';
import styles from './styles.scss';

const Tertiary = ({skeleton, skeletonWidth, skeletonProps, ...props}) => {
  if (skeleton) {
    return <SkeletonHeading width={skeletonWidth} height="24px" data-ui="skeleton-h3" {...skeletonProps} />;
  }

  return <TextBase as="h3" {...props} className={clsx(styles.h3, props.className)} />;
};

Tertiary.propTypes = {
  ...TextBase.propTypes
};

Tertiary.displayName = 'Heading.Tertiary';

export default Tertiary;
