import axios from 'axios';
import config from 'candidate/config';
import i18n from 'candidate/i18n';
import {Errors} from 'candidate/shared/enums';
import withAuth from './auth';

export const errorHandler = error => (axios.isCancel(error) ? Promise.resolve() : Promise.reject(error));
export const noop = response => response;
export const locale = request => {
  if (request && request.headers && i18n.language) {
    request.headers['Accept-Language'] = i18n.language;
  }
  return request;
};

export const resetContent = response => {
  if (response && response.status && response.status === 205) {
    return Promise.reject(Errors.NEXT_PAGE_TOKEN_EXPIRED);
  }
  return response;
};

export const http = (_window = window) =>
  config.then(({apis, meta}) => {
    // If it is custom domain use location.href to send requests for careers page
    const careersBaseURL = (meta.hasCustomDomain && new URL(_window.location.href).origin) || apis.careers.url;
    const instance = axios.create();
    instance.form = axios.create({baseURL: careersBaseURL, withCredentials: true});
    instance.careers = axios.create({baseURL: careersBaseURL, withCredentials: true});
    instance.acp = axios.create({baseURL: apis.advanced.url, withCredentials: true});
    instance.profile = axios.create({baseURL: apis.careers.url, withCredentials: true});

    instance.careers.interceptors.request.use(locale);
    instance.form.interceptors.request.use(locale);

    instance.form.interceptors.response.use(noop, errorHandler);
    instance.careers.interceptors.response.use(resetContent);

    return withAuth(instance);
  });

export default http();
