import React from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import delegateRef from '../../helpers/delegateRef';

class Ref extends React.Component {
  componentDidMount() {
    delegateRef(this.props.$ref, findDOMNode(this));
  }

  componentDidUpdate() {
    delegateRef(this.props.$ref, findDOMNode(this));
  }

  componentWillUnmount() {
    delegateRef(this.props.$ref, null);
  }

  render() {
    const {children} = this.props;

    return React.Children.only(children);
  }
}

Ref.propTypes = {
  children: PropTypes.element.isRequired,

  /** The delegate ref of the child component */
  $ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default Ref;
