import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import styles from './graph.scss';

const SvgGraph = ({
  as: GraphType = 'g',
  transparent,
  success,
  caution,
  danger,
  primary,
  secondary,
  emphasis,
  warning,
  fill,
  muted,
  neutral,
  dataPlacement,
  dataUi,
  ...props
}) => {
  const styleNames = `graph ${mapPropsToStyleNames([
    {transparent, warning, success, caution, danger, primary, secondary, emphasis, muted, neutral, default: 'default'},
    {fill}
  ])}`;

  return (
    <GraphType
      {...{...props, ...(fill ? {fillRule: 'evenodd'} : {})}}
      className={clsx(
        styleNames
          .split(' ')
          .map(c => styles[c])
          .join(' '),
        props.className
      )}
    />
  );
};

SvgGraph.propTypes = {
  /** Fills the svg */
  fill: PropTypes.bool,
  /** Color the illustration with success theme color */
  success: PropTypes.bool,
  /** Color the illustration with danger theme color */
  danger: PropTypes.bool,
  /** Color the illustration with caution theme color */
  caution: PropTypes.bool,
  /** Color the illustration with warning theme color */
  warning: PropTypes.bool,
  /** Color the illustration with neutral theme color */
  primary: PropTypes.bool,
  /** Color the illustration with emphasis theme color */
  secondary: PropTypes.bool,
  /** Color the illustration with neutral theme color */
  neutral: PropTypes.bool,
  /** Color the illustration with muted theme color */
  muted: PropTypes.bool,
  /** Color the illustration with emphasis theme color */
  emphasis: PropTypes.bool,
  /** Morph the Graph as different tag. Defaults to <g> */
  as: PropTypes.string
};

export default SvgGraph;
