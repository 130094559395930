import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

const Icon = ({className, graphClassName, transform, ...props}) => (
  <BaseSvg {...props} className={className}>
    <Graph fill {...props} className={graphClassName} transform={transform}>
      <path d="M6.7 13.7l1.4-1.4L3.8 8H16V6H3.8l4.3-4.3L6.7.3 0 7z" transform="translate(0 1)" />
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.ArrowLeft';

setSymbol(Icon);

export default Icon;
