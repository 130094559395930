import {resolverSymbol} from './setDefaultDynamicTexts';

const resolvedTextsMap = new WeakMap();

const warnForMissingTranslationFunction = translationKey => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(`
      Can't handle dynamic translation for "${translationKey}". No translation function available.
      Available locales can be found at \`shared/ui/locales\`. Provide the proper one by
      passing \`texts\` prop to component and override the "${translationKey}" key with a translate
      function.
      <Component texts={
        {
          ...locales,
          ${translationKey}: (options) =>
            yourI18nImpl.t(locales.${translationKey}.$, options)
        }
      />
    `);
  }
};

const setDefaultDynamics = (localizedTexts, defaultTexts = {}) => {
  const texts = {...defaultTexts};
  Object.keys(texts).forEach(textKey => {
    const textVal = localizedTexts[textKey] || '';
    const defaultVal = defaultTexts[textKey] || '';
    const {[resolverSymbol]: customStringResolver} = textVal;
    const {[resolverSymbol]: defaultStringResolver} = defaultVal;

    if (defaultStringResolver) {
      if (typeof defaultStringResolver !== 'function') {
        throw new Error('No default function found for that key');
      }

      if (typeof textVal !== 'function') {
        if (textVal && defaultStringResolver !== customStringResolver) {
          throw new Error(`Locale text property "${textKey}" expected to be a function`);
        }

        if (localizedTexts !== defaultTexts) {
          warnForMissingTranslationFunction(textKey);
        }
        texts[textKey] = defaultStringResolver;
        return;
      }

      texts[textKey] = textVal;
      return;
    }

    if (typeof defaultVal === 'object') {
      texts[textKey] = setDefaultDynamics(textVal, defaultVal);
      return;
    }

    texts[textKey] = textVal || defaultVal;
  });

  return texts;
};

const withDynamicTexts = (localizedTexts, defaultTexts) => {
  if (resolvedTextsMap.has(localizedTexts)) {
    return resolvedTextsMap.get(localizedTexts);
  }

  const texts = setDefaultDynamics(localizedTexts, defaultTexts);

  resolvedTextsMap.set(localizedTexts, texts);

  return texts;
};

export default withDynamicTexts;
