import React from 'react';
import PropTypes from 'prop-types';
import generateColorVariables from 'shared/ui/helpers/colors/generateVariables';
import applyCssVariablesToRoot from 'shared/ui/helpers/applyCssVariablesToRoot';

const isIE = () => {
  const ua = window.navigator.userAgent;
  return (/MSIE|Trident/).test(ua);
};

const Theme = ({theme, style, children, ...props}) => {
  const cssVars = generateColorVariables(theme);

  if (!children) {
    applyCssVariablesToRoot(cssVars);
  }

  if (isIE()) {
    import(/* webpackMode: "lazy", webpackChunkName: "css-vars-ponyfill" */
      'css-vars-ponyfill').then(({default: polyfill}) => polyfill({variables: cssVars}));
  }

  return children ? (
    <div {...props} style={{...style, ...cssVars}}>
      {children}
    </div>
  ) : null;
};

Theme.defaultProps = {
  theme: {}
};

Theme.propTypes = {
  /** The object with color variables, that will be translated to css vars. */
  theme: PropTypes.object
};

export default Theme;
