import isScreenshotTesting from './../../../config/webpack/environment/isScreenshotTesting';
import isTestEnv from './../../../config/webpack/environment/isTest';

/**
 * Observes changes in the size of the body including when adding/removing scrollbar
 */
class BodyWidthObserver {
  constructor(callback) {
    if (typeof callback !== 'function') {
      throw new Error('Callback is not a function');
    }
    this.callback = callback;
  }

  observe() {
    if (isScreenshotTesting() || isTestEnv()) {
      return;
    }

    this.detector = document.createElement('iframe');

    Object.assign(this.detector.style, {
      height: 0,
      border: 0,
      width: '100%',
      display: 'block'
    });

    document.body.appendChild(this.detector);

    if (!this.detector.contentWindow) {
      document.body.removeChild(this.detector);
      throw new Error('No content window found on iFrame');
    }
    this.detectorWindow = this.detector.contentWindow;

    // Detect when width changes hereafter.
    this.detectorWindow.addEventListener('resize', this.callback);
  }

  disconnect() {
    if (!this.detectorWindow) {
      return;
    }

    this.detectorWindow.removeEventListener('resize', this.callback);
    document.body.removeChild(this.detector);
  }
}

export default BodyWidthObserver;
