import React, {createContext, useContext, useState, useCallback, useEffect} from 'react';
import debounce from 'lodash/debounce';
import detectDevice, {mediaQueriesBuilder} from 'shared/contexts/device/detectDevice';

export const Device = Object.freeze({
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile'
});

export const BREAKPOINTS = {
  Mobile: ['only screen and (max-width: 639px)'],
  Tablet: ['only screen and (max-width: 1023px)']
};

const MEDIA_QUERIES = mediaQueriesBuilder()
  .addQueryWithSpecificity(Device.Mobile, BREAKPOINTS.Mobile)
  .addQueryWithSpecificity(Device.Tablet, BREAKPOINTS.Tablet)
  .setLargerDevice(Device.Desktop);

const DeviceStateContext = createContext();
const DeviceUpdaterContext = createContext();

const DeviceProvider = ({children, mobileMediaQueries}) => {
  const [device, setDevice] = useState(() => detectDevice(mobileMediaQueries));

  useEffect(() => setDevice(detectDevice(mobileMediaQueries)), [mobileMediaQueries]);

  return (
    <DeviceStateContext.Provider value={device}>
      <DeviceUpdaterContext.Provider value={setDevice}>{children}</DeviceUpdaterContext.Provider>
    </DeviceStateContext.Provider>
  );
};

const useDeviceState = () => {
  return useContext(DeviceStateContext);
};

const useDeviceUpdater = mobileMediaQueries => {
  const setDevice = useContext(DeviceUpdaterContext);
  return useCallback(() => setDevice(detectDevice(mobileMediaQueries)), [setDevice, mobileMediaQueries]);
};

export const useDevice = mobileMediaQueries => {
  return [useDeviceState(), useDeviceUpdater(mobileMediaQueries)];
};

const Responsive = ({children, mobileMediaQueries}) => {
  const [, updateDevice] = useDevice(mobileMediaQueries);

  useEffect(() => {
    const debounceUpdateDevice = debounce(updateDevice, 60);

    window.addEventListener('resize', debounceUpdateDevice);
    window.addEventListener('orientationchange', debounceUpdateDevice);

    return () => {
      if (debounceUpdateDevice.cancel) {
        debounceUpdateDevice.cancel();
      }
      window.removeEventListener('resize', debounceUpdateDevice);
      window.removeEventListener('orientationchange', debounceUpdateDevice);
    };
  }, [updateDevice]);

  return children;
};

/**
 * DeviceProvider is replicated from `shared/contexts/device/responsive/component.js`
 *
 * The reason for the replication is that we need to separate the contexts between the EvergreenResponsiveProvider
 * with the DeviceProvider in order to prevent conflicts in the applications.
 *
 * The above, could have been achieved by refactoring the DeviceProvider but we chose not to,
 * since the component was already very complex and this type of refactoring would make it unmaintainable.
 */
const EvergreenResponsiveProvider = ({children}) => {
  return (
    <DeviceProvider mobileMediaQueries={MEDIA_QUERIES}>
      <Responsive mobileMediaQueries={MEDIA_QUERIES}>{children}</Responsive>
    </DeviceProvider>
  );
};

export default EvergreenResponsiveProvider;
