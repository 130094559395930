import React from 'react';
import clsx from 'clsx';
import TextBase from 'shared/ui/atoms/text/base';
import {SkeletonHeading} from 'shared/ui/atoms/skeleton';
import styles from './styles.scss';

const Secondary = ({skeleton, skeletonWidth, skeletonProps, ...props}) => {
  if (skeleton) {
    return (
      <SkeletonHeading
        width={skeletonWidth}
        height="32px"
        data-ui="skeleton-h2"
        {...skeletonProps}
        className={clsx(skeletonProps?.className, styles.skeleton)}
      />
    );
  }
  return <TextBase as="h2" {...props} className={clsx(styles.h2, props.className)} />;
};

Secondary.propTypes = {
  ...TextBase.propTypes
};

Secondary.displayName = 'Heading.Secondary';

export default Secondary;
