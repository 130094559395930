module.exports = {
  apis: {
    profile: {
      url: 'https://profile-api.workable.com'
    },
    careers: {
      url: 'https://apply.workable.com'
    }
  },
  gtm: {
    id: 'GTM-PTM8DJ8',
    auth: 'SnIbsAEp_PIySZa6CM-HLw',
    env: 'env-1'
  },
  zendesk: {
    script: 'https://static.zdassets.com/ekr/snippet.js?key=a25dfb95-4023-4d0f-8695-3c7cbe565f56',
    ticketFormIds: [360003337873],
    isEnabled: true
  },
  shield: {
    profile: {
      origin: 'https://id.workable.com',
      clientId: 'profile_spa',
      audience: ['profile_server'],
      scope: ['openid', 'profile', 'email', 'permissions', 'roles', 'account'],
      redirectUri: 'https://profile.workable.com/auth/shield/callback',
      leeway: 600,
      timeout: 15
    }
  }
};
