import React from 'react';
import Skeleton from '../component';
import SkeletonBody from './body';

export type SkeletonTextareaProps = React.HTMLAttributes<HTMLDivElement> & {
  height?: string;
  heading?: boolean;
};

const SkeletonTextarea = ({heading, height = '124px', ...props}: SkeletonTextareaProps) => {
  return (
    <div {...props}>
      {heading && <SkeletonBody height="20px" style={{marginBottom: '8px'}} />}
      <Skeleton width="100%" height={height} darker={false} />
    </div>
  );
};

SkeletonTextarea.displayName = 'SkeletonTextarea';

export default SkeletonTextarea;
