import React from 'react';
import {attach, dettach} from './helpers';
import './styles.scss';

export default class Outline extends React.Component {
  constructor(props) {
    super(props);
    this.eventsAttached = false;
  }

  componentDidMount() {
    if (this.eventsAttached) {
      return;
    }

    this.eventsAttached = true;
    attach();
  }

  componentWillUnmount() {
    this.eventsAttached = false;
    dettach();
  }

  render() {
    return this.props.children || null;
  }
}
