import React from 'react';
import Skeleton, {SkeletonProps} from '../component';

export type SkeletonImageProps = Omit<SkeletonProps, 'width' | 'height' | 'darker' | 'borderRadius'> & {
  size: string;
  box?: boolean;
};

const SkeletonImage = ({size, box, ...props}: SkeletonImageProps) => {
  return <Skeleton {...props} width={size} height={size} borderRadius={box ? '4px' : '100%'} darker={false} />;
};

SkeletonImage.displayName = 'SkeletonImage';

export default SkeletonImage;
