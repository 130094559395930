module.exports = {
  environment: 'production',
  apis: {
    careers: {
      url: 'https://apply.workable.com'
    },
    advanced: {
      url: 'https://site-editor.workable.com'
    }
  },
  googlemaps: {
    key: 'AIzaSyBknMbmEEGETWdDErn68htJEcZHRI0hrq0'
  },
  datadog: {
    clientToken: 'pub779cccf74cbc25ad7035bb1d1119117c',
    applicationId: '4e317328-d6e4-459b-b1a2-e4040f4b463a'
  },
  gapi: {
    appId: '61022300302',
    clientId: '61022300302-erasmeuvfa97umt40jupqfdfuj9t2lha.apps.googleusercontent.com',
    developerKey: 'AIzaSyARdf711KddkQqk7Pyxndp1ajm697HMM94'
  },
  dropbox: {
    key: 'x7lwcu9z6hn2ry5'
  },
  gtm: {
    id: 'GTM-WKS7WTT',
    auth: 'SGnzIn3pcB7S4fevFXOKPQ',
    env: 'env-2'
  },
  siteEditorGTM: {
    id: 'GTM-55SGQ9T',
    auth: 'N2G9jHSp3fHkiFednlVouw',
    env: 'env-1'
  },
  recaptcha: {
    clientKey: '6Lf0mbgUAAAAAMTxUJd2kP-dnnAOAqJm3yrkbBxz'
  },
  shield: {
    basic: {
      origin: 'https://id.workable.com',
      clientId: 'cp_client',
      audience: ['cp_server'],
      scope: ['openid', 'profile', 'email', 'permissions', 'roles', 'account'],
      redirectUri: 'https://apply.workable.com/auth/shield',
      leeway: 600
    },
    advanced: {
      origin: 'https://id.workable.com',
      clientId: 'acp_client',
      audience: ['cp_server'],
      scope: ['openid', 'profile', 'email', 'permissions', 'roles', 'account'],
      redirectUri: 'https://site-editor.workable.com/auth/shield/callback',
      leeway: 600,
      timeout: 15
    }
  },
  fallback: 'https://jobs.workable.com/',
  ignoredHostnames: ['wrkbl.ink'],
  indeedToken: '3a51613a4d8b9799d352130065868b0c34bce36cee7f4dffa3ed16b0c7936634',
  enableLinkedin: true,
  upscope: true,
  features: {
    datadogEnabled: true,
    faviconEnabled: true
  }
};
