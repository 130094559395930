import React, {useEffect, useState} from 'react';
import ExternalSvg from 'assets/svg/external12.svg?sprite';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import {matchPath} from 'react-router';
import {setSymbol} from 'shared/ui/atoms/icon/base';
import SVGSprite from 'shared/ui/atoms/icon/SVGLoaders/sprite';
import i18n, {I18n} from 'candidate/i18n';
import AccountDetails from 'candidate/providers/account/details';
import Job from 'candidate/providers/job';
import parseURL from 'candidate/shared/parseurl';
import paths, {FILTERS} from 'candidate/shared/paths';
import styles from './styles.scss';

const ExternalIcon = () => <SVGSprite fill size={12} svg={ExternalSvg} transparent />;

setSymbol(ExternalIcon);

export const Notice = ({'data-ui': dataUi, message, url}) => {
  if (!url) {
    return null;
  }

  const lng = i18n.language;
  let finalUrl;

  if (lng) {
    const parsed = parseURL(url);
    finalUrl = `${parsed.origin}${parsed.pathname}?${qs.stringify({...(parsed.querystring || {}), lng})}${parsed.hash}`; // eslint-disable-line max-len
  } else {
    finalUrl = url;
  }
  return (
    <I18n className={styles.compliance} data-ui={dataUi} Node="span" url={finalUrl}>
      {message}
    </I18n>
  );
};

export const Compliance = ({account, ccpa, gdpr}) => {
  const hasGdrp = !isEmpty(gdpr) && gdpr.url;
  const hasCcpa = !isEmpty(ccpa) && ccpa.url;

  if (!hasCcpa && !hasGdrp) {
    return null;
  }

  return (
    <div>
      <I18n account={account} className={styles.compliance} data-ui="footer-compliance-notice" Node="span">
        footer.complianceNotice
      </I18n>
      {hasGdrp ? <Notice {...(gdpr || {})} data-ui="footer-gdpr" message="footer.gdpr" /> : null}
      {hasCcpa ? <Notice {...(ccpa || {})} data-ui="footer-ccpa" message="footer.ccpa" /> : null}
    </div>
  );
};

export const Eeoc = ({account, eeoc}) =>
  eeoc ? <div data-ui="footer-eeoc">{eeoc.disclaimer || i18n.t('footer.eeoc', {account})}</div> : null;

export const Disclaimers = ({account}) => {
  if (!account) {
    return null;
  }
  const {ccpa, eeoc, gdpr} = account.details || {};

  const hasGdrp = !isEmpty(gdpr) && gdpr.url;
  const hasCcpa = !isEmpty(ccpa) && ccpa.url;

  if (!hasCcpa && !hasGdrp && !eeoc) {
    return null;
  }

  return (
    <div className={styles.disclaimers}>
      <Compliance account={account.name} ccpa={ccpa} gdpr={gdpr} />
      <Eeoc account={account.name} eeoc={eeoc} />
    </div>
  );
};

export const Shimmer = () => (
  <div className={styles.footer}>
    <div className={styles.links}>
      <a className={styles.shimmerPoweredBy} />
    </div>
  </div>
);

export const PoweredBy = () => {
  const utmParams = '?utm_campaign=careers_page&utm_content=careers_page&utm_medium=feature&utm_source=careers_page';
  return (
    <div className={styles.poweredBy} data-ui="footer-powered-by">
      <span className={styles.link}>
        <a
          data-ui="accessibility"
          href="http://workable.com/web-accessibility-statement"
          rel="noreferrer"
          target="_blank"
        >
          {i18n.t('footer.accessibility')}
          <ExternalIcon />
        </a>
      </span>
      <span className={styles.link}>
        {i18n.t('footer.poweredBy')}
        <a href={`https://jobs.workable.com/${utmParams}`} rel="noreferrer" target="_blank">
          Workable
        </a>
      </span>
    </div>
  );
};

export const Footer = ({account, className, job, path = window.location.pathname}) => {
  const [locale, setLocale] = useState(i18n.language || 'en');

  const change = l => setLocale(l);

  useEffect(() => {
    i18n.on('languageChanged', change);

    return () => {
      i18n.off('languageChanged', change);
    };
  }, []);

  if (matchPath(path, {path: FILTERS})) {
    return null;
  }

  if (!account) {
    return <Shimmer />;
  }

  return (
    <footer className={classnames(styles.footer, className)} data-id="footer">
      <Disclaimers account={account} />
      <div className={styles.links}>
        {account && account.url ? (
          <div className={styles.wrapper}>
            <a data-ui="company-url" href={account.url} rel="noreferrer" target="_blank">
              {i18n.t('footer.viewWebsite')}
            </a>
          </div>
        ) : null}
        <div className={styles.allJobsHelp}>
          {job ? (
            <a data-ui="view-jobs-url" href={paths.root()}>
              {i18n.t('footer.viewAllJobs')}
            </a>
          ) : null}
          <a data-ui="help-url" href={`https://jobseekers.workable.com/hc/${locale}`} rel="noreferrer" target="_blank">
            {i18n.t('footer.help')}
            <ExternalIcon />
          </a>
        </div>
      </div>
      <PoweredBy />
    </footer>
  );
};

export default props => (
  <AccountDetails.Consumer>
    {({accountDetails: account}) => (
      <Job.Consumer>{({job}) => <Footer account={account} job={job} {...props} />}</Job.Consumer>
    )}
  </AccountDetails.Consumer>
);
