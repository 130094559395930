import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withTranslations, getEvergreenTranslations} from 'shared/ui/providers/translations';
import getRandomString from 'shared/ui/helpers/getRandomString';
import Dialog from 'shared/ui/organisms/dialog';
import ButtonTertiary from 'shared/ui/atoms/button/tertiary';
import TextBody from 'shared/ui/atoms/text/body';
import IconRemove from 'shared/ui/atoms/icon/remove';

import styles from './styles.scss';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';

const render = (content, props = {}) => {
  const toBeRendered = content ? (
    typeof content === 'string' ? (
      <TextBody {...props} color="white" strong>
        {content}
      </TextBody>
    ) : React.isValidElement(content) ? (
      React.cloneElement(content, props)
    ) : null
  ) : null;

  if (React.isValidElement(toBeRendered)) {
    props.id = null;
  }

  return toBeRendered;
};

class AlertFlash extends React.Component {
  constructor(props) {
    super(props);
    this.randomId = getRandomString();
  }

  render() {
    const {children, id = this.randomId, texts: _texts, message, onClose, danger, informational, ...props} = this.props;
    const {ROLE} = Dialog.Container.constants;

    const {t} = getEvergreenTranslations(this.props);
    const texts = t('alert.flash', _texts);

    const ariaProps = {
      'aria-atomic': true,
      'aria-describedby': message ? `${id}_alert_body` : '',
      'aria-modal': false,
      role: danger ? ROLE.ALERT : ROLE.STATUS
    };

    const metaProps = {
      id: ariaProps['aria-describedby'],
      'data-role': 'text'
    };
    const styleNames = mapPropsToStyleNames([{danger, informational, default: 'informational'}]);

    return (
      <Dialog.Container
        {...ariaProps}
        {...props}
        className={clsx(
          {
            [styles['alert-flash']]: true
          },
          styleNames
            .split(' ')
            .map(style => styles[style])
            .join(' '),
          props.className
        )}
        id={id}
        onDismiss={onClose}
      >
        {render(message, metaProps)}
        {render(children, metaProps)}
        <Dialog.Actions className={styles.actions} dismissIndex={0}>
          <ButtonTertiary aria-label={texts.dismiss}>
            <IconRemove color="white" transparent size={12} />
          </ButtonTertiary>
        </Dialog.Actions>
      </Dialog.Container>
    );
  }
}

AlertFlash.displayName = 'Alert.Flash';

AlertFlash.propTypes = {
  /** The message to display inside the flash alert */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Used to denote error messages. Paints the modal according to DSTF guidelines */
  danger: PropTypes.bool,
  /** Used to denote info messages. Paints the modal according to DSTF guidelines */
  informational: PropTypes.bool,
  /** Triggered when modal closes. */
  onClose: PropTypes.func,
  /** An object with locales for the component */
  texts: PropTypes.object
};

export default withTranslations(AlertFlash);
