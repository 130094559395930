import React from 'react';
import PropTypes from 'prop-types';
import Ref from 'shared/ui/behaviors/ref';

const getVendorPrefixed = rule => {
  if (rule in document.body.style) {
    return rule;
  }

  const prefixes = ['moz', 'o', 'webkit', 'ms'];

  return (
    prefixes
      .map(pfx => pfx + rule.charAt(0).toUpperCase() + rule.substr(1, rule.length))
      .find(pfxRule => document.body.style[pfxRule] !== undefined) || rule
  );
};

const getAnimationEnd = () => {
  const animationMap = {
    animation: 'animationend',
    mozAnimation: 'mozAnimationEnd',
    webkitAnimation: 'webkitAnimationEnd',
    oAnimation: 'oanimationend',
    msAnimation: 'MSAnimationEnd'
  };

  const animation = getVendorPrefixed('animation');

  return animationMap[animation] || 'animationend';
};

const getTransitionEnd = () => {
  const transitionMap = {
    transition: 'transitionend',
    mozTransition: 'transitionend',
    oTransition: 'oTransitionEnd',
    webkitTransition: 'webkitTransitionEnd'
  };

  const transition = getVendorPrefixed('transition');

  return transitionMap[transition] || 'transitionend';
};

const runCallback = callback => {
  return new Promise(resolve => {
    window.requestAnimationFrame(async () => {
      await callback();
      resolve();
    });
  });
};

const shouldCompleteTransition = (currentEvent, transitingStyleName, doneCondition) => {
  const matchesAnimation = currentEvent.animationName && currentEvent.animationName.indexOf(transitingStyleName) > -1;
  const matchesTransition = currentEvent.propertyName === transitingStyleName;
  const nameMatches = matchesAnimation || matchesTransition;

  return (
    !transitingStyleName ||
    (nameMatches &&
      (typeof doneCondition !== 'function' ||
        doneCondition(window.getComputedStyle(currentEvent.target), transitingStyleName)))
  );
};

/**
 * By setting simply a `handleCompleted` method prop, it catches all the transitions which will occur on the
 * underlying element and passes them over to `handleCompleted`
 *
 * By specifying an additional `name` prop, will catch only transitions on the CSS property which matches the
 * specified name and pass them on `handleCompleted`
 *
 * By specifying an additional `done` method prop, along with `name`, will catch all the CSS transition whose
 * name matches the given name and the the condition on `done` method is satisfied. The done signature should be
 * `(value, name)` for the CSS property.
 *
 * An example where we log something in console when opacity falls to zero:
 @example
  <CSSTransition
    name="opacity"
    done={opacity => Number(opacity) === 0}
    handleCompleted={() => console.log('Div hidden')}
  >
    <div>UI here</div>
  </CSSTransition>
 */
const CSSTransition = ({name, duration = 0.001, delay = 0, done, handleCompleted = () => {}, ...props}) => {
  const child = React.Children.only(props.children);
  let prevRef;

  const handleTransitionEnd = evt => {
    if (typeof handleCompleted !== 'function') {
      return;
    }

    if (!evt || prevRef !== evt.target || !shouldCompleteTransition(evt, name, done)) {
      return;
    }

    const userHandledTransition = child.props.onTransitionEnd;
    const userHandledAnimation = child.props.onAnimationEnd;

    runCallback(handleCompleted).then((...args) => {
      if ('propertyName' in evt && typeof userHandledTransition === 'function') {
        return userHandledTransition();
      }

      if ('animationName' in evt && typeof userHandledAnimation === 'function') {
        return userHandledAnimation();
      }
      return args;
    });
  };

  const attachIfNoTransitionExists = node => {
    if (prevRef && prevRef !== node) {
      prevRef.removeEventListener(getTransitionEnd(), handleTransitionEnd);
      prevRef.removeEventListener(getAnimationEnd(), handleTransitionEnd);
    }

    if (!node || !node.style) {
      return;
    }

    prevRef = node;

    const transition = getVendorPrefixed('transition');
    const transitionStyle = node.style[transition] || window.getComputedStyle(node)[transition];

    const areExistingTransitions = Boolean(transitionStyle && !transitionStyle.includes('all 0s'));
    const noneTransitionExists = Boolean(transitionStyle && transitionStyle.includes('none'));

    if (noneTransitionExists) {
      handleTransitionEnd({target: prevRef});
      return;
    }

    if (!areExistingTransitions && !name) {
      handleTransitionEnd({target: prevRef});
      return;
    }

    if (name && (!transitionStyle || !transitionStyle.includes(`${name} `))) {
      const transitionRule = `${name} ${duration}s linear ${delay}s`;
      node.style[transition] = areExistingTransitions ? `${transitionStyle}, ${transitionRule}` : transitionRule;
    }

    prevRef.addEventListener(getTransitionEnd(), handleTransitionEnd);
    prevRef.addEventListener(getAnimationEnd(), handleTransitionEnd);
  };

  return <Ref $ref={attachIfNoTransitionExists}>{child}</Ref>;
};

CSSTransition.propTypes = {
  /** The function to run when a transition is completed */
  handleCompleted: PropTypes.func,
  /**
   * The name of the property transiting. If none is passed the
   * `handleCompleted` will be called for any transition ocurred.
   */
  name: PropTypes.string,
  /**
   * Duration of the transition in seconds. Default 0.001.
   * If no transition is already defined a new transition
   * will be declared for the underlying element
   */
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Transition delay. Same logic applies as in `duration` */
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If `handleCompleted` should run conditionally for certain values
   * of the CSS transition property, use this function to check the
   * value the CSS property has by the time the transition completed
   * If no function passed the `handleCompleted` will be called on
   * every transition ocurred for that property
   */
  done: PropTypes.func
};

export {getTransitionEnd, getVendorPrefixed, getAnimationEnd};

export default CSSTransition;
