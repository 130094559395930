import React from 'react';
import Button from 'shared/ui/atoms/button';
import i18n from 'candidate/i18n';
import {PoweredBy} from 'candidate/layout/footer';
import paths from 'candidate/shared/paths';
import styles from './styles.scss';

const Generic = ({buttonText = null, buttonUrl = null, Icon, message, subMessage = null, title}) => (
  <>
    <div className={styles.container}>
      {Icon ? <Icon /> : null}
      <h1>{title}</h1>
      <p>{message}</p>
      {subMessage && <p>{subMessage}</p>}
      <Button.Primary href={buttonUrl || paths.root()}>{buttonText || i18n.t('errors.action')}</Button.Primary>
    </div>
    <div className={styles.footer}>
      <PoweredBy />
    </div>
  </>
);

export default Generic;
