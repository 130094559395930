import darkenColor from './darken';

export const VARIATIONS = [5, 10, 15];

const getColorVarName = name => (name.startsWith('--') ? name : `--${name}`);
export const getDarkenName = (color, variation) => `${getColorVarName(color)}${variation ? `-darker${variation}` : ''}`;

export default function generateColorVariations(colors = {}) {
  return Object.keys(colors).reduce((acc, color) => {
    const baseColorValue = colors[color];

    if (baseColorValue) {
      const colorVar = getColorVarName(color);
      acc[colorVar] = baseColorValue;
      acc[`${colorVar}-rgb`] = darkenColor(baseColorValue, 0, true);

      VARIATIONS.forEach(
        variation => (acc[getDarkenName(colorVar, variation)] = darkenColor(baseColorValue, variation))
      );
    }

    return acc;
  }, {});
}
