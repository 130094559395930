import Loadable from 'react-loadable';
import Loader from '../withLoader/loader';

/**
 * make React Loadable
 * ------
 * Dynamic component loader
 *
 * Usage:
 * ```
 * import React from 'react';
 * import makeLoadable from 'shared/components/makeLoadable';
 *
 * const load = makeLoadable(options);
 * const Component = load(() => import('path/to/component'));
 * or with chunk name, use webpack's magic comment
 * const Component = load(() => /* webpackChunkName: "some_specific_chunk_name" * / import('path/to/component'));
 * ```
 *
 *                     !!!IMPORTANT!!!
 *                     Make sure to catch the errors in your loader - if make use of custom Loader component
 *
 */

export default ({loading = Loader, delay = 200} = {}) => loader =>
  Loadable({
    loading,
    delay,
    loader: () => loader().then(Component => (Component.default ? Component.default : Component))
  });
