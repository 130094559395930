import i18n from 'candidate/i18n';

export const LABELS = Object.freeze({
  address: () => i18n.t('form.fields.address'),
  avatar: () => i18n.t('form.fields.photo'),
  cover_letter: () => i18n.t('form.fields.coverLetter'),
  education: () => i18n.t('form.fields.education'),
  email: () => i18n.t('form.fields.email.title'),
  experience: () => i18n.t('form.fields.experience'),
  firstname: () => i18n.t('form.fields.firstname'),
  headline: () => i18n.t('form.fields.headline'),
  lastname: () => i18n.t('form.fields.lastname'),
  phone: () => i18n.t('form.fields.phone'),
  resume: () => i18n.t('form.fields.resumeLabel'),
  summary: () => i18n.t('form.fields.summary'),
  texting_consent: () => null
});

export const TYPES = Object.freeze({
  address: 'text',
  avatar: 'file',
  boolean: 'boolean',
  cover_letter: 'paragraph',
  date: 'date',
  dropdown: 'dropdown',
  education: 'group',
  email: 'email',
  experience: 'group',
  file: 'file',
  firstname: 'text',
  free_text: 'paragraph',
  headline: 'text',
  lastname: 'text',
  multiple_choice: 'multiple',
  numeric: 'number',
  phone: 'phone',
  resume: 'file',
  short_text: 'text',
  summary: 'paragraph'
});
