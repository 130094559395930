const TRUTHY_STRINGS = ['yes', 'true'];
const FALSY_STRINGS = ['no', 'false'];

const defaultFeaturesGetter = () => window?.careers?.features || {};

const getMetaAttribute = attribute =>
  window.document.head.querySelector(`meta[name="${attribute}"]`)?.getAttribute('content');

const getAccountName = () => getMetaAttribute('subdomain')?.toLowerCase();

const getAccountId = () => getMetaAttribute('account')?.toLowerCase();

const getArrayFeatureValue = featureValue => {
  if (featureValue instanceof Array) {
    return featureValue;
  }

  if (typeof featureValue === 'string') {
    return featureValue.toLowerCase().split(/\s|,/g).filter(Boolean);
  }

  return [];
};

const isFeatureAllowed = featureValue => {
  if (!featureValue || FALSY_STRINGS.indexOf(featureValue?.toLowerCase?.()) > -1) {
    return false;
  }

  if (featureValue === true || TRUTHY_STRINGS.indexOf(featureValue?.toLowerCase?.()) > -1) {
    return true;
  }

  const arrayFeatureValue = getArrayFeatureValue(featureValue);

  if (arrayFeatureValue) {
    const matchesAnyOfTheAccounts = arrayFeatureValue.indexOf(getAccountName()) > -1;
    const matchesAnyAccountId = arrayFeatureValue.indexOf(getAccountId()) > -1;
    return matchesAnyOfTheAccounts || matchesAnyAccountId;
  }

  return false;
};

const getAllowedFeatures = allFeatures => Object.keys(allFeatures).filter(f => isFeatureAllowed(allFeatures[f]));

const featurePolicy = (_features = defaultFeaturesGetter) => {
  let features = typeof _features === 'function' ? _features() : _features;

  if (!features || Object.prototype.toString.call(features) !== '[object Object]') {
    console.error(
      [
        'Invalid features inside featurePolicy.',
        'Make sure features are an object or a function which returns an object.',
        'Sets everything to false'
      ].join(' ')
    );
    features = {};
  }

  const allowedFeatures = getAllowedFeatures(features);

  return {
    allowedFeatures: () => [...allowedFeatures],
    allows: featureName => allowedFeatures.indexOf(featureName) > -1,
    features: () => ({...features})
  };
};

export default featurePolicy;
