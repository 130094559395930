import merge from 'lodash/merge';

import withDynamicTexts from 'shared/ui/helpers/locales/withDynamicTexts';

import {DynamicText, Locale, LocaleShorthand, LocaleTexts, TranslationContextType} from './types';
import {LOCALE_SHORTHANDS} from './constants';

export const isString = (value: any): value is string => typeof value === 'string';
export const isDynamicText = (value: any): value is DynamicText => typeof value === 'function';
export const isLocaleShorthand = (value: any): value is LocaleShorthand => value in LOCALE_SHORTHANDS;

/**
 * This function is used to get the correct locale from a shorthand or a locale
 *
 * @param locale
 * @returns
 */
export const getConvertedLocale = (locale: Locale | LocaleShorthand): Locale => {
  if (isLocaleShorthand(locale)) {
    return LOCALE_SHORTHANDS[locale];
  }

  return locale;
};

/**
 * This function is used to create a nested object from a path and a value
 *
 * @param path
 * @param value
 * @returns
 */
export const createNestedObject = (path: string, value: LocaleTexts | LocaleTexts[number]) => {
  if (!path) {
    return value;
  }

  const keys = path.split('.');
  const result: LocaleTexts = {};
  let current = result;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (i === keys.length - 1) {
      current[key] = value;
    } else {
      current[key] = {};
      current = current[key] as {[key: string]: LocaleTexts};
    }
  }

  return result;
};

/**
 * This function is used to find the translation for a given path in the translations object
 *
 * @param path
 * @param translations
 * @returns
 */
export const translate = (path: string, translations: LocaleTexts) => {
  const dynamicTranslations = withDynamicTexts(translations, translations) as LocaleTexts;

  if (!path) {
    return dynamicTranslations;
  }

  const keys = path.split('.');

  let value: LocaleTexts | LocaleTexts[number] = dynamicTranslations;

  for (const key of keys) {
    if (isString(value)) {
      break;
    }

    if (isDynamicText(value)) {
      break;
    }

    const next: LocaleTexts | LocaleTexts[number] = value[key];

    if (!next) {
      // eslint-disable-next-line no-console
      console.warn(`EvergreenTranslations: Translation key "${path}" not found`);

      return path;
    }

    value = next;
  }

  return value;
};

/**
 * This function is used to get the translations from the context,
 * it can only be used in components that are wrapped with the `withTranslations` HOC
 *
 * @param props
 * @returns {TranslationContextType}
 */
export const getEvergreenTranslations = (props: any): TranslationContextType => {
  return (props as {__evergreenTranslations: TranslationContextType}).__evergreenTranslations;
};

/**
 * This factory function is used to create a `t` function that can be used to get translations
 * for a given path and locale
 *
 * @param database
 * @param locale
 * @returns
 */
export const createTFunction = (translations: LocaleTexts) => {
  return (path: string, _overrides: LocaleTexts = {}) => {
    const mergedTranslations = _overrides
      ? merge({}, translations, createNestedObject(path, _overrides))
      : translations;

    return translate(path, mergedTranslations);
  };
};
