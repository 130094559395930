import React from 'react';
import clsx from 'clsx';
import propsFilter from 'shared/ui/helpers/propsFilter';

import styles from './styles.scss';

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement> & {
  width: string;
  height: string;
  borderRadius?: string;
  darker?: boolean;
  className?: string;
  style?: React.CSSProperties;
  'data-ui'?: string;
  'data-testId'?: string;
};

const Skeleton = ({width, height, darker, borderRadius = '4px', style = {}, ...props}: SkeletonProps) => {
  const filteredProps = propsFilter(props).ariaAttributes().dataAttributes().styles().getFiltered() as typeof props;

  return (
    <div
      {...filteredProps}
      className={clsx(
        {
          [styles.skeleton]: true,
          [styles.darker]: darker
        },
        filteredProps.className
      )}
      style={{...style, width, height, borderRadius}}
    />
  );
};

Skeleton.displayName = 'Skeleton';

export default Skeleton;
