import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import {setSymbol} from '../base';
import KeyboardHandlers from 'shared/ui/behaviors/keyboardHandler';
import propsFilter from 'shared/ui/helpers/propsFilter';
import symbols from 'shared/ui/symbols';
import styles from './styles.scss';

const ActionableIcon = ({children, tabIndex, role, as: Kind, ...props}) => {
  const allowedProps = Object.keys(KeyboardHandlers.defaultHandlers).join('|');
  const filteredHandlers = propsFilter(props).like(new RegExp(allowedProps));

  const keyboardHandlerProps = filteredHandlers.getFiltered();
  const containerProps = filteredHandlers.like(/size/).excludeFiltered();

  return (
    (<KeyboardHandlers {...keyboardHandlerProps}>
      <Kind {...containerProps} className={clsx(styles["actionable-icon"], containerProps.className)} tabIndex={tabIndex} role={role}>
        {children}
      </Kind>
    </KeyboardHandlers>)
  );
};

setSymbol(ActionableIcon);

ActionableIcon[symbols.Button.Actionable] = true;

ActionableIcon.defaultProps = {
  role: 'button',
  tabIndex: '0',
  as: 'div'
};

ActionableIcon.propTypes = {
  ...KeyboardHandlers.propTypes,
  /** The tabIndex will be applied to the actionable item. '0' by default. */
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The role of the actionable item. 'button' by default. */
  role: PropTypes.string,
  /** Morphs container into another Component or element. Defaults to `div` */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

export default ActionableIcon;
