import React, {forwardRef} from 'react';
import clsx from 'clsx';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import propTypes from './proptypes/button';
import KeyboardHandler from 'shared/ui/behaviors/keyboardHandler';
import {SkeletonButton} from 'shared/ui/atoms/skeleton';
import CircularLoader from 'shared/ui/molecules/loader/circular';
import symbols, {hasSymbol} from 'shared/ui/symbols';

import styles from './button.scss';

const prevent = e => e.nativeEvent.preventDefault();

const isIcon = child => child && child.type && hasSymbol(symbols.Icon, child.type);

const updatedChild = child => {
  if (!React.isValidElement(child) || !isIcon(child)) {
    return child;
  }
  return React.cloneElement(child, {size: child.props.size || 12});
};

const handleClickLikeEvent = e => {
  prevent(e);
  e.currentTarget.click();
};

const renderChildren = (children, loading) => {
  if (loading) {
    return (
      <>
        <CircularLoader small />
        {children}
      </>
    );
  }

  if (React.Children.count(children) <= 1) {
    return children;
  }

  return React.Children.map(children, child => updatedChild(child));
};

const ButtonElement = ({loading, ...props}) => <button {...props} />;

const CustomElement = ({disabled, CustomType, children, onClick, ...restProps}) => {
  const roleProps = restProps.href ? {'data-role': 'button-link'} : {role: 'button'};
  const props = {...restProps, onClick: disabled ? prevent : onClick};

  return (
    <KeyboardHandler handleEnterPressed={handleClickLikeEvent} handleSpacePressed={handleClickLikeEvent}>
      <CustomType tabIndex="0" {...roleProps} {...props}>
        {children}
      </CustomType>
    </KeyboardHandler>
  );
};

const AnchorElement = ({disabled, href, ...restProps}) => {
  const anchorProps = disabled ? {} : {href};
  return CustomElement({...restProps, ...anchorProps, disabled, CustomType: 'a'});
};

const Button = forwardRef(
  (
    {
      disabled,
      href,
      loading,
      responsive,
      target,
      small,
      rel,
      type,
      as: CustomType,
      skeleton,
      skeletonProps,
      children,
      ...restProps
    },
    ref
  ) => {
    if (skeleton) {
      return <SkeletonButton small={small} data-ui="skeleton-button" {...skeletonProps} />;
    }

    const classNames = mapPropsToStyleNames([{button: true}, {small, default: 'normal'}, {responsive}]);
    const isDisabled = loading || disabled;

    const generator = href ? AnchorElement : CustomType ? CustomElement : ButtonElement;
    const generatorOptions = {
      ...restProps,
      children: renderChildren(children, loading),
      ...(href ? {href, rel, target} : {}),
      ...(!href && CustomType ? {CustomType} : {}),
      ...(!href && !CustomType && type ? {type} : {}),
      className: clsx(
        classNames
          .split(' ')
          .map(className => styles[className])
          .join(' '),
        restProps.className
      ),
      loading,
      disabled: isDisabled,
      ...(isDisabled ? {'aria-disabled': isDisabled} : {}),
      ref
    };
    return generator(generatorOptions);
  }
);

Button.propTypes = {
  ...propTypes
};

Button.displayName = 'Button.Base';

export default Button;
