import React from 'react';
import symbols from 'shared/ui/symbols';
import ArrowLeft from 'shared/ui/atoms/icon/arrowLeft';
import ButtonTertiary from 'shared/ui/atoms/button/tertiary';
import DrawerHeaderLabel from './label';

import styles from './styles.scss';

const DropdownHeader = ({label, hasArrow, onClose}) => (
  <div className={styles["dropdown-header"]} data-role="drawer-header">
    {hasArrow ? (
      <div className={styles["arrow-container"]} data-role="drawer-arrow-container">
        <ButtonTertiary neutral onClick={onClose} aria-label="Dismiss">
          <ArrowLeft size="16" transparent />
        </ButtonTertiary>
      </div>
    ) : (
      <div className={styles["separator-container"]} data-role="drawer-header-separator">
        <div className={styles.separator} />
      </div>
    )}

    {label && <DrawerHeaderLabel>{label}</DrawerHeaderLabel>}
  </div>
);

DropdownHeader[symbols.Alert.Static] = true;

export default DropdownHeader;
