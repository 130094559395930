import React from 'react';
import PropTypes from 'prop-types';

const page = ({pathname, search} = {}) => [pathname, search].filter(_ => _).join('');

/*
 * Emits a Google Tag Manager pageview event in each route change.
 */
export class PageviewEmitter extends React.Component {
  componentDidMount() {
    this.emit();
  }

  shouldComponentUpdate(nextProps) {
    const _page = typeof this.props.page === 'function' ? this.props.page : page;
    const before = _page(this.props && this.props.location);
    const after = _page(nextProps && nextProps.location);
    return before !== after;
  }

  componentDidUpdate() {
    this.emit();
  }

  emit() {
    const _page = typeof this.props.page === 'function' ? this.props.page : page;
    const pageview = _page(this.props && this.props.location);
    const analytics = this.props && this.props.analytics;
    return analytics && analytics.then(a => pageview && a && a.pageview(pageview).send());
  }

  render() {
    return null;
  }
}

PageviewEmitter.defaultProps = {
  page
};

PageviewEmitter.propTypes = {
  /** An analytics object which includes all GTM functionality (emits pageView event or custom event) */
  analytics: PropTypes.object,
  /** A function to tranform the page path string into a desired format to be emitted as pageview */
  page: PropTypes.func
};
