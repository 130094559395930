import isEmpty from 'lodash/isEmpty';
import matches from 'lodash/matches';
import pickBy from 'lodash/pickBy';
import memoize from 'memoizee';

const lowercase = s => s && s.toLowerCase();

const memoized = memoize(
  (account, _) => _.jobs().then(({results, ...rest}) => ({...rest, results: results && results.reverse()})),
  {
    length: 1,
    maxAge: 5 * 60 * 1000,
    primitive: true,
    promise: true // 5 minutes.
  }
);

const _matcher = (_query, _departments = [], locations = [], _worktypes = [], remote = []) => ({
  matches: job => {
    const query = _query && _query.toLowerCase();
    const departments = _departments.map(lowercase);
    const worktypes = _worktypes.map(lowercase);
    const _title = job.title && job.title.toLowerCase();
    const _department = job.department && job.department.toLowerCase();
    const _worktype = job.type && job.type.toLowerCase();
    const _location = job.location && pickBy(job.location);
    const _remote = job.remote || false;
    return (
      (query ? _title.includes(query) : true) &&
      (isEmpty(departments) || departments.includes(_department)) &&
      (isEmpty(locations) || (!isEmpty(_location) && locations.some(l => matches(l)(_location)))) &&
      (isEmpty(worktypes) || worktypes.includes(_worktype)) &&
      (isEmpty(remote) || remote.includes(_remote))
    );
  }
});

const jobs = async (account, search, _) => {
  const {results: all, ...rest} = await memoized(account, _);
  const {department = [], location = [], query, remote, worktype} = (search && search.params) || {};
  const matcher = _matcher(query, department, location.map(pickBy), worktype, remote);
  const filtered = all.filter(job => matcher.matches(job));
  return {...rest, results: filtered};
};

export default ({account, ...rest}) => ({
  account: name => {
    const delegate = account(name);

    return {
      ...delegate,
      jobs: params => jobs(name, params, delegate)
    };
  },
  ...rest
});
