import React from 'react';
import texts from './texts.json';
import setDefaultDynamicTexts from 'shared/ui/helpers/locales/setDefaultDynamicTexts';

export default Object.freeze(
  setDefaultDynamicTexts(texts, {
    tour: {
      progress: ({current, total}) => `Συμβουλή ${current} από ${total}`
    },
    cookieConsent: {
      text: ({companyName}) =>
        `Η εταιρεία ${companyName} χρησιμοποιεί cookies για να βελτιώνει την εμπειρία σου στον ιστότοπό μας. Προτού συνεχίσεις ενημέρωσέ μας αν επιθυμείς να αποδεχτείς τη χρήση cookies σύμφωνα με την`,
      textSettings: ({cookiesPolicy}) => (
        <span>
          Ο ιστότοπος χρησιμοποιεί cookies για την αποθήκευση πληροφοριών στον υπολογιστή σου. Τα cookies είναι μικρά
          αρχεία κειμένου που βελτιώνουν την εμπειρία του χρήστη. Επιλέγοντας «Αποδοχή όλων» συναινείς στη χρήση όλων
          των cookies, ενώ αν επιλέξεις «Απόρριψη όλων» μόνο τα απαραίτητα cookies θα αποθηκευτούν στο πρόγραμμα
          περιήγησής σου. Μπορείς να διαβάσεις την {cookiesPolicy} μας αλλά και να προσαρμόσεις τις ρυθμίσεις των
          cookies σου:
        </span>
      ),
      sections: {
        about: {
          description: ({cookiesPolicy}) => (
            <span>
              Ο ιστότοπος χρησιμοποιεί cookies για την αποθήκευση πληροφοριών στον υπολογιστή σου. Τα cookies είναι
              μικρά αρχεία κειμένου που βελτιώνουν την εμπειρία του χρήστη. Μπορείς να διαβάσεις την {cookiesPolicy} μας
              αλλά και να προσαρμόσεις τις ρυθμίσεις των cookies σου:
            </span>
          )
        }
      }
    }
  })
);
