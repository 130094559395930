import React from 'react';
import Skeleton, {SkeletonProps} from '../component';

export type SkeletonHeadingProps = Omit<SkeletonProps, 'width' | 'height' | 'darker'> & {
  link?: boolean;
  width?: SkeletonProps['width'];
  height?: SkeletonProps['height'];
};

const SkeletonHeading = ({width = '80px', height = '24px', link, ...props}: SkeletonHeadingProps) => {
  return <Skeleton {...props} width={width} height={height} darker={link} />;
};

SkeletonHeading.displayName = 'SkeletonHeading';

export default SkeletonHeading;
