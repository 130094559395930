import React, {useState, useCallback} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BaseInput from 'shared/ui/atoms/input/base';
import ExclamationIcon from 'shared/ui/atoms/icon/exclamation';
import CheckMarkIcon from 'shared/ui/atoms/icon/checkmark';
import IllustratedContainer from 'shared/ui/atoms/icon/container';
import SuffixText from 'shared/ui/atoms/text/body';
import symbols from 'shared/ui/symbols';
import {SkeletonInput} from 'shared/ui/atoms/skeleton';
import styles from './styles.scss';

const getChildrenIcons = children => {
  let hasCustomIcon = false;

  const childrenIcons = React.Children.map(children, (Child, idx) => {
    if (!Child) {
      return;
    }

    if (!Child.type[symbols.Icon]) {
      throw new Error('Input.Text accepts only Icon components as children.');
    }

    hasCustomIcon = true;

    const {size = '16', ...props} = Child.props;

    return <Child.type key={idx} {...props} className={clsx(styles['status-icon'], props.className)} size={size} />;
  });

  return {childrenIcons, hasCustomIcon};
};

const Text = React.forwardRef(
  (
    {
      as: InputKind = BaseInput,
      success,
      error,
      warning,
      readOnly,
      suffix,
      disabled,
      type = 'text',
      children,
      className,
      skeleton,
      skeletonProps,
      ...props
    },
    ref
  ) => {
    const [isNonInputChildFocused, setIsNonInputChildFocused] = useState(false);

    const handleFocus = useCallback(({target}) => {
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.isContentEditable) {
        return setIsNonInputChildFocused(false);
      }
      setIsNonInputChildFocused(true);
    }, []);

    const handleBlur = useCallback(() => setIsNonInputChildFocused(false), []);

    if (skeleton) {
      return <SkeletonInput data-ui="skeleton-input" {...skeletonProps} />;
    }

    const errorIcon = error ? <ExclamationIcon className={styles['status-icon']} size="16" danger /> : null;
    const successIcon = success ? <CheckMarkIcon className={styles['status-icon']} size="16" success /> : null;
    const warningIcon = warning ? <ExclamationIcon className={styles['status-icon']} size="16" warning /> : null;

    const isTextarea = InputKind && InputKind[symbols.Input.Textarea];

    const {childrenIcons, hasCustomIcon} = getChildrenIcons(children);

    const suffixText = suffix ? (
      <SuffixText className={styles.suffix} muted>
        {suffix}
      </SuffixText>
    ) : null;

    return (
      <IllustratedContainer
        ref={ref}
        data-role="illustrated-input"
        className={clsx(
          {
            [styles.input]: true,
            [styles.error]: error,
            [styles.warning]: warning,
            [styles.readonly]: readOnly,
            [styles.disabled]: disabled,
            [styles.textarea]: isTextarea,
            [styles['has-icon']]: error || warning || success || (isTextarea && hasCustomIcon),
            [styles['focused-inside']]: isNonInputChildFocused
          },
          className
        )}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <InputKind readOnly={readOnly} disabled={disabled} {...props} type={type} />
        {suffixText}
        {errorIcon}
        {successIcon}
        {warningIcon}
        {childrenIcons}
      </IllustratedContainer>
    );
  }
);

Text.displayName = 'Text';

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string
};

export default Text;
