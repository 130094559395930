import React from 'react';
import Skeleton, {SkeletonProps} from '../component';

export type SkeletonButtonProps = Omit<SkeletonProps, 'width' | 'height' | 'darker'> & {
  small?: boolean;
};

const SkeletonButton = ({small, ...props}: SkeletonButtonProps) => {
  return <Skeleton {...props} width={small ? '89px' : '92px'} height={small ? '32px' : '44px'} darker />;
};

SkeletonButton.displayName = 'SkeletonButton';

export default SkeletonButton;
