import getRealReactChild from './getRealReactChild';

/**
 *
 *  @param {Object} wrappedChild
 *  @param {Object} propMatcher An object containing the props to retrieve as keys. If
 *   an object gets passed as value with a `default` property will return that instead
 *   if none found in react child.
 *  @param {Object|Any} propMatcher.value
 *  @param {String|Function|Boolean} propMatcher.value.default the default if none found;
 *  @returns {Object}
 *  @example
 *  ```js
    getNestedChildProps(React.Children.only(children),
      {
        onClick: { default: () => {}},
        enabled: undefined
      }
    );
 * */
const getRealChildProps = (wrappedChild, propsMatch = {}) => {
  const child = getRealReactChild(wrappedChild);
  const childProps = child.props;
  const matched = {};

  Object.keys(propsMatch).forEach(key => {
    if (key in childProps) {
      matched[key] = childProps[key];
      return;
    }
    if (propsMatch[key] && typeof propsMatch[key] === 'object' && 'default' in propsMatch[key]) {
      matched[key] = propsMatch[key].default;
      return;
    }
  });

  return matched;
};

export default getRealChildProps;
