import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BaseLoader from '../base';
import withMemo from 'shared/ui/helpers/hocs/withMemo';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import TextBody from 'shared/ui/atoms/text/body';
import Rotate from 'shared/ui/behaviors/animated/rotate';
import {setSymbol} from 'shared/ui/atoms/icon/base';
import styles from './styles.scss';

const getMinLoadingTime = ({small, medium, large}) => {
  if (small) {
    return 100;
  }

  if (medium) {
    return 200;
  }

  if (large) {
    return 300;
  }
};

const CircularLoaderView = ({small, medium, large, texts, horizontal, inverted, ...props}) => {
  const loaderStyleName = mapPropsToStyleNames([
    {inverted, loader: true},
    {small, medium, large, default: 'large'}
  ]);

  return (
    <div
      {...props}
      className={clsx(
        {
          [styles.container]: true,
          [styles.horizontal]: horizontal,
          [styles.inverted]: inverted
        },
        props.className
      )}
    >
      <div
        className={loaderStyleName
          .split(' ')
          .map(name => styles[name])
          .join(' ')}
      >
        <Rotate iterations={Infinity} duration={1500} easing="linear" animate>
          <span />
        </Rotate>
      </div>
      {texts && <TextBody>{texts}</TextBody>}
    </div>
  );
};

const MemoCircularLoaderView = withMemo(CircularLoaderView);

const CircularLoader = ({loading, small, medium, large, horizontal, inverted, backdrop, texts, ...props}) => {
  const circularLoader = (
    <MemoCircularLoaderView
      small={small}
      medium={medium}
      large={large}
      texts={texts}
      horizontal={horizontal}
      inverted={inverted || backdrop}
      data-role="loader circular"
    />
  );

  const minLoadingTime = getMinLoadingTime({small, medium, large});

  return (
    <BaseLoader
      loader={circularLoader}
      loading={loading}
      backdrop={backdrop}
      minLoadingTime={minLoadingTime}
      {...props}
    />
  );
};

const MemoCircularLoader = withMemo(CircularLoader);

MemoCircularLoader.propTypes = {
  loading: PropTypes.bool,
  texts: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  inverted: PropTypes.bool,
  horizontal: PropTypes.bool,
  backdrop: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

setSymbol(MemoCircularLoader);

export default MemoCircularLoader;
