import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import symbols, {hasSymbol} from 'shared/ui/symbols';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import withMemo from 'shared/ui/helpers/hocs/withMemo';
import ELEVATION_OPTIONS from 'shared/constants/elevations';

import IllustratedContainer from 'shared/ui/atoms/icon/container';
import ButtonTertiary from 'shared/ui/atoms/button/tertiary';

import isProd from '../../../../../config/webpack/environment/isProd';

import styles from './styles.scss';

const isProduction = isProd();
export const ERROR_FOR_ACCESSIBLE_NAME =
  'Icon-button cannot have text that is visible to screen readers, so it should have `aria-label` or `aria-labelledby` attribute to obtain discernible text.';

const IconButton = React.forwardRef(
  (
    {
      small,
      onClick,
      disabled,
      white,
      transparent,
      outlined,
      brand,
      as = ButtonTertiary,
      elevation,
      children,
      dark,
      ...props
    },
    ref
  ) => {
    const accessibleNameIsMissing = !props['aria-label'] && !props['aria-labelledby'];
    if (!isProduction && accessibleNameIsMissing) {
      throw new Error(ERROR_FOR_ACCESSIBLE_NAME);
    }

    const isButtonTertiary = hasSymbol(symbols.Button.Tertiary, as);
    const isDark = dark && (transparent || outlined);

    const classNames = mapPropsToStyleNames({
      'icon-button': true,
      'custom-button': !isButtonTertiary,
      small,
      disabled,
      white,
      transparent,
      outlined,
      dark: isDark,
      [`elevation-${elevation}`]: ELEVATION_OPTIONS.includes(elevation)
    });

    const processedChildren = React.Children.map(children, child => {
      if (!child) {
        return;
      }

      if (child.type && child.type[symbols.Icon]) {
        const newProps = {
          transparent: true,
          size: child.props.size || (small ? 12 : 16),
          color: child.props.color || (isDark ? 'white' : undefined)
        };

        if (!newProps.color) {
          delete newProps.color;
        }

        return React.cloneElement(child, newProps);
      }

      return child;
    });

    return (
      <IllustratedContainer
        ref={ref}
        {...props}
        disabled={disabled}
        onClick={onClick}
        className={clsx(
          classNames
            .split(' ')
            .map(name => styles[name])
            .join(' '),
          props.className
        )}
        as={as}
        defaultFlavor={!brand ? 'neutral' : undefined}
      >
        {processedChildren}
      </IllustratedContainer>
    );
  }
);

const MemoIconButton = withMemo(IconButton);

MemoIconButton[symbols.Button.Icon] = true;

MemoIconButton.displayName = 'IconButton';

MemoIconButton.propTypes = {
  /** Controls whether the button is disabled. */
  disabled: PropTypes.bool,
  /** Controls whether the button is small. */
  small: PropTypes.bool,
  /** The box-shadow level. Default: 'none' */
  elevation: PropTypes.oneOf(ELEVATION_OPTIONS)
};

export {ELEVATION_OPTIONS};

export default MemoIconButton;
