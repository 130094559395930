export default function delegateRef(ref, element) {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref.current = element;
    ref(element);
    return;
  }

  if (typeof ref === 'object' && 'current' in ref) {
    ref.current = element;
    return;
  }

  window.setTimeout(() => {
    throw new Error('The ref type passed can not be handled.');
  }, 1);
}
