export const getURLInstance = (url = window?.location) => {
  if (typeof url === 'string') {
    return new URL(url);
  }

  if (url instanceof URL || url instanceof Location) {
    return url;
  }
};

export const getEnvironmentFromHostname = (hostname = window.location.hostname) => {
  const hostParts = hostname.split('.');
  const domain = hostParts[hostParts.length > 1 ? hostParts.length - 2 : hostParts.length - 1].toLowerCase();
  const environment = hostname.endsWith('workable.com')
    ? 'production'
    : hostname.endsWith('workable.dev') || hostname.endsWith('localhost') || hostname.endsWith('lvh.me')
    ? 'development'
    : domain;

  return environment;
};

export const getSiteVersion = () => {
  const fallbackVersion = 'v0.0.0';

  if (typeof __VERSION_OR_COMMIT_HASH__ !== 'undefined') {
    // eslint-disable-next-line no-undef
    return __VERSION_OR_COMMIT_HASH__;
  }

  try {
    return document.querySelector('meta[name="version"]').content;
  } catch (e) {
    // skip, default version resolution not supported. App should handle it
  }

  return fallbackVersion;
};

export const canEnvironmentRunQAPerformance = environment => environment === 'workablestg10';
