import React, {useRef} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withColor} from 'shared/ui/helpers/hocs/withTextStyles';
import getRandomString from 'shared/ui/helpers/getRandomString';
import propsFilter from 'shared/ui/helpers/propsFilter';
import symbols from 'shared/ui/symbols';
import styles from './styles.scss';

const svgFallback = <p>SVGs not supported by this browser.</p>;

const BaseSvg = withColor(
  ({
    size = 16,
    width,
    height,
    responsive,
    fallback = svgFallback,
    title,
    children,
    className,
    style,
    transform,
    viewBox,
    preserveAspectRatio,
    dataPlacement,
    dataUi,
    id: idFromParent,
    ...props
  }) => {
    const randomId = useRef(getRandomString());
    const id = idFromParent || randomId.current;
    const titleId = `svg_title_${id}`;
    const ariaAttributes = propsFilter(props).ariaAttributes().getFiltered();

    return (
      <svg
        style={style}
        id={id}
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        focusable="false"
        viewBox={viewBox || '0 0 16 16'}
        width={width || size}
        height={height || size}
        transform={transform}
        preserveAspectRatio={preserveAspectRatio || 'xMidYMin slice'}
        data-placement={dataPlacement}
        data-ui={dataUi}
        {...(title ? {'aria-labelledby': titleId} : {'aria-hidden': 'true'})}
        {...(Object.keys(ariaAttributes).length ? ariaAttributes : undefined)}
        className={clsx([{[styles.svg]: true, [styles.responsive]: responsive}], className)}
      >
        {title ? <title id={titleId}>{title}</title> : <desc aria-hidden="true">{fallback}</desc>}
        {children}
      </svg>
    );
  }
);

BaseSvg.propTypes = {
  /** Scales the illustration to fill its container */
  responsive: PropTypes.bool,
  /** Fallback HTML or text when SVG is not supported */
  fallback: PropTypes.any,
  /** The size in pixels */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The width in pixels, defaults to `size` instead. */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The height in pixels, defaults to `size` instead. */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Accessibility title */
  title: PropTypes.string,
  /** SVG transform prop */
  transform: PropTypes.string,
  /** SVG viewbox prop */
  viewBox: PropTypes.string,
  /** SVG preserveAspectRatio prop */
  preserveAspectRatio: PropTypes.string
};

BaseSvg.displayName = 'Icon.BaseSvg';

export const setSymbol = Component => {
  Component[symbols.Icon] = true;

  return Component;
};

export default BaseSvg;
