export const CLOSE_ACTION = {
  DISMISS: 'dismiss',
  CLOSE: 'close',
  CANCEL: 'cancel'
} as const;

export const ROLE = {
  ALERT: 'alert',
  DIALOG: 'dialog',
  ALERTDIALOG: 'alertdialog',
  STATUS: 'status',
  PRESENTATION: 'presentation',
  COMPLEMENTARY: 'complementary',
  NAVIGATION: 'navigation',
  TOOLTIP: 'tooltip',
  MENU: 'menu'
} as const;

export const FORM_PROPS = 'onSubmit|noValidate|autoComplete|name|action|encType|method|target';
