import {getEnvironmentFromHostname, getSiteVersion, canEnvironmentRunQAPerformance, getURLInstance} from './utils';

// https://docs.datadoghq.com/real_user_monitoring/browser/
const computeDefaultConfig = () => {
  const {hostname, origin} = getURLInstance(window.location);

  return {
    applicationId: undefined,
    clientToken: undefined,
    startSessionReplayRecordingManually: true,
    site: 'datadoghq.com',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    service: undefined,
    env: getEnvironmentFromHostname(hostname),
    version: getSiteVersion(),
    allowedTracingUrls: [origin],
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    silentMultipleInit: true,
    enableExperimentalFeatures: ['feature_flags'] // This enables the beta feature flag tracking
  };
};

let datadogRum;

const _datadog = configOrBuilderFunction =>
  new Promise(async (resolve, reject) => {
    const defaultConfig = computeDefaultConfig();
    const isQARun = canEnvironmentRunQAPerformance(defaultConfig.env);
    const datadogSDK = isQARun
      ? await import(
          /* webpackMode: "lazy", webpackChunkName: "datadog-sdk" */
          '@datadog/browser-rum'
        )
      : await import(
          /* webpackMode: "lazy", webpackChunkName: "datadog-sdk-slim" */
          '@datadog/browser-rum-slim'
        );

    let _config = configOrBuilderFunction;
    if (typeof _config === 'function') {
      _config = await _config(datadogSDK);
    }
    const {applicationId, clientToken, ...rest} = _config || {};

    if (!applicationId || !clientToken) {
      return reject('Datadog client keys are missing');
    }

    let qaOverwrites = {};
    if (isQARun) {
      qaOverwrites = {
        allowUntrustedEvents: true,
        defaultPrivacyLevel: 'allow',
        trackInteractions: true,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100
      };
    }

    const config = Object.assign({}, defaultConfig, rest, {applicationId, clientToken}, qaOverwrites);

    datadogRum = datadogSDK.datadogRum.init(config) || datadogSDK.datadogRum;

    datadogRum.onReady(() => resolve({rum: datadogRum, isQARun}));
  }).then(({rum, isQARun}) => {
    if (isQARun) {
      try {
        rum.startSessionReplayRecording();
      } catch (e) {
        // not supported version. skipping
      }
    }

    return rum;
  });

export default config => {
  const dd = _datadog(config);

  return {
    context: (key, value) => ({
      add: () =>
        dd.then(_datadogRum => {
          _datadogRum.setGlobalContextProperty(key, value);
        })
    }),

    action: (name, info) => ({
      add: () => dd.then(_datadogRum => _datadogRum.addAction(name, info))
    })
  };
};
