const EVERGREEN_SYMBOL_PREFIX = 'evergreen';

if (typeof process === 'undefined') {
  window.process = {
    env: {
      NODE_ENV: undefined
    }
  };
}

export const $Symbol = (key, prefix = EVERGREEN_SYMBOL_PREFIX) =>
  process.env.NODE_ENV === 'development' ? `workable.${prefix}.${key}` : Symbol.for(`workable.${prefix}.${key}`);

const namespaced = symbols => ({
  ...symbols,
  includes: componentType => Object.keys(symbols).filter(name => componentType[symbols[name]]).length > 0
});

const symbols = {
  Behavior: $Symbol('Behavior'),
  Button: namespaced({
    Primary: $Symbol('Button.Primary'),
    Secondary: $Symbol('Button.Secondary'),
    Tertiary: $Symbol('Button.Tertiary'),
    Ghost: $Symbol('Button.Ghost'),
    Tonal: $Symbol('Button.Tonal'),
    DropDown: $Symbol('Button.DropDown'),
    Confirmation: $Symbol('Button.Confirmation'),
    Group: $Symbol('Button.Group'),
    NavLink: $Symbol('Button.NavLink'),
    NavLinkGroup: $Symbol('Button.NavLinkGroup'),
    Actionable: $Symbol('Button.Actionable'),
    Icon: $Symbol('Button.Icon'),
    Floating: $Symbol('Button.Floating')
  }),
  Dialog: {
    ...namespaced({
      Container: $Symbol('Dialog.Container'),
      Actions: $Symbol('Dialog.Actions'),
      Title: $Symbol('Dialog.Title'),
      Content: $Symbol('Dialog.Content'),
      Subtitle: $Symbol('Dialog.Subtitle'),
      Navigation: $Symbol('Dialog.Navigation'),
      MobileNavigation: $Symbol('Dialog.MobileNavigation'),
      Notifications: $Symbol('Dialog.Notifications'),
      Header: $Symbol('Dialog.HeaderSlot')
    }),
    DropDown: $Symbol('Dialog.DropDown')
  },
  Modal: $Symbol('Dialog.Modal'),
  Drawer: $Symbol('Dialog.Drawer'),
  PageWithDrawer: $Symbol('PageWithDrawer'),
  Control: {
    Radio: $Symbol('Filed.Radio')
  },
  FilePreview: namespaced({
    Image: $Symbol('FilePreview.Image'),
    Video: $Symbol('FilePreview.Video'),
    File: $Symbol('FilePreview.File')
  }),
  Image: namespaced({
    TombStoned: $Symbol('Image.TombStoned'),
    Text: $Symbol('Image.Text'),
    Logo: $Symbol('Image.Logo')
  }),
  Icon: $Symbol('Icon'),
  ListBox: $Symbol('ListBox'),
  ListItem: namespaced({
    SimpleNonSelectable: $Symbol('ListItem.SimpleNonSelectable'),
    SimpleSelectable: $Symbol('ListItem.SimpleSelectable'),
    SimpleSelectableWithHelper: $Symbol('ListItem.SimpleSelectableWithHelper'),
    WithAvatar: $Symbol('ListItem.WithAvatar')
  }),
  Avatar: {
    Base: $Symbol('Avatar.Base'),
    Action: $Symbol('Avatar.Action')
  },
  Group: namespaced({
    Generic: $Symbol('Group.Generic'),
    Button: $Symbol('Group.Button'),
    Radio: $Symbol('Group.Radio')
  }),
  Tabs: namespaced({
    Tab: $Symbol('Tabs.Tab'),
    Counter: $Symbol('Tabs.Counter')
  }),
  Input: namespaced({
    Radio: namespaced({
      Labeled: $Symbol('Input.Radio.Labeled'),
      Naked: $Symbol('Input.Radio.Naked'),
      Button: $Symbol('Input.Radio.Button'),
      Rich: $Symbol('Input.Radio.Rich')
    }),
    Checkbox: namespaced({
      Labeled: $Symbol('Input.Checkbox.Labeled'),
      Naked: $Symbol('Input.Checkbox.Naked'),
      Tertiary: $Symbol('Input.Checkbox.Tertiary')
    }),
    Inline: $Symbol('Input.Inline'),
    Textarea: $Symbol('Input.Textarea'),
    Autocomplete: $Symbol('Input.Autocomplete')
  }),
  ProfileAvatar: $Symbol('Nav.Avatar'),
  NavSeparator: $Symbol('NavSeparator'),
  Alert: namespaced({
    Static: $Symbol('Alert.Static')
  }),
  AutocompleteRule: $Symbol('Input.AutocompleteRule'),
  BooleanSearchInput: $Symbol('BooleanSearchInput'),
  BlankSlate: namespaced({
    Illustration: $Symbol('BlankSlate.Illustration'),
    Title: $Symbol('BlankSlate.Title'),
    Body: $Symbol('BlankSlate.Body')
  }),
  FlapModal: namespaced({
    Illustration: $Symbol('FlapModal.Illustration'),
    Overlay: $Symbol('FlapModal.Overlay'),
    Headline: $Symbol('FlapModal.Headline'),
    Body: $Symbol('FlapModal.Body')
  }),
  Filter: namespaced({
    Dropdown: $Symbol('Filter.Dropdown')
  })
};

export function withSymbol(symbol, Component) {
  if (symbol && Component) {
    Component[symbol] = true;
  }

  return Component;
}

export function hasSymbol(symbol, Component) {
  if (!symbol || !Component) {
    return false;
  }

  if (typeof symbol === 'object' && typeof symbol.includes === 'function') {
    return symbol.includes(Component);
  }

  return Component[symbol] === true;
}

export default symbols;
