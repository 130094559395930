function getRandomString() {
  // Characters that can be used in the string
  const firstCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const characters = `${firstCharacters}0123456789`;

  const length = 16;

  // Generate first character (alphabet only)
  let result = firstCharacters.charAt(Math.floor(Math.random() * firstCharacters.length));

  // Generate the rest of the characters
  for (let i = 1; i < length; i = i + 1) {
    result = result + characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

export default getRandomString;
