import i18n from 'candidate/i18n';

export const TYPES = Object.freeze({
  contract: () => i18n.t('job.type.contract'),
  full: () => i18n.t('job.type.full'),
  other: () => i18n.t('job.type.other'),
  part: () => i18n.t('job.type.part'),
  temporary: () => i18n.t('job.type.temporary')
});

export const WORKPLACES = Object.freeze(
  Object.assign(Object.create(null), {
    hybrid: () => i18n.t('advanced.component.job.workplace.hybrid'),
    on_site: () => i18n.t('advanced.component.job.workplace.onsite'),
    remote: () => i18n.t('advanced.component.job.workplace.remote')
  })
);
