import getContainingBlockElement from './getContainingBlockElement';
import getDocumentWidth from './getDocumentWidth';

/**
 * Returns the width of document
 * If the element has a containing block, it returns the width of the containing block.
 *
 * @param {HTMLElement} element
 * @returns {number} `number`
 */
const getContainingBlockWidth = element => {
  const containingBlockElement = getContainingBlockElement(element);

  if (containingBlockElement) {
    return containingBlockElement.clientWidth;
  }

  return getDocumentWidth();
};

export default getContainingBlockWidth;
