import {useEffect, useState} from 'react';
import configuration from 'candidate/profile/config';

const useConfig = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    configuration.then(mod => setConfig(mod.default)).catch(() => setConfig(null));
  }, []);

  return config;
};

export default useConfig;
