import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import Ref from 'shared/ui/behaviors/ref';

import isScreenshotTesting from '../../../../config/webpack/environment/isScreenshotTesting';
import isTestEnv from '../../../../config/webpack/environment/isTest';

const STATUS = {
  UNMOUNTED: 'unmounted',
  START_TO_ENTER: 'start_to_enter',
  ENTERING: 'entering',
  ENTERED: 'entered',
  START_TO_EXIT: 'start_to_exit',
  EXITING: 'exiting'
};

const getTransitionAtrributes = status => {
  if (status === STATUS.START_TO_ENTER) {
    return ['data-transition-enter'];
  }

  if (status === STATUS.ENTERING) {
    return ['data-transition-enter', 'data-transition-entering'];
  }

  if (status === STATUS.ENTERED) {
    return ['data-transition-entered'];
  }

  if (status === STATUS.START_TO_EXIT) {
    return ['data-transition-exit'];
  }

  if (status === STATUS.EXITING) {
    return ['data-transition-exit', 'data-transition-exiting'];
  }

  return [];
};

const delayExecution = (callback, delayInMs) => {
  if (isScreenshotTesting() || isTestEnv()) {
    callback();
    return;
  }

  return setTimeout(callback, delayInMs);
};

const Transition = ({
  mounted,
  duration,
  onTransitionEnterStarted,
  onTransitionEnterFinished,
  onTransitionExitStarted,
  onTransitionExitFinished,
  children
}) => {
  const isFirstRenderRef = useRef(true);
  const [status, setStatus] = useState(STATUS.UNMOUNTED);

  const targetRef = useRef();
  const timeoutIdRef = useRef();

  useEffect(() => {
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    if (status === STATUS.START_TO_ENTER) {
      setStatus(STATUS.ENTERING);

      if (typeof onTransitionEnterStarted === 'function') {
        onTransitionEnterStarted(targetRef.current);
      }

      timeoutIdRef.current = delayExecution(() => {
        setStatus(STATUS.ENTERED);
      }, duration);
    }

    if (status === STATUS.ENTERED) {
      if (typeof onTransitionEnterFinished === 'function') {
        onTransitionEnterFinished(targetRef.current);
      }
    }

    if (status === STATUS.START_TO_EXIT) {
      setStatus(STATUS.EXITING);

      if (typeof onTransitionExitStarted === 'function') {
        onTransitionExitStarted(targetRef.current);
      }

      timeoutIdRef.current = delayExecution(() => {
        setStatus(STATUS.UNMOUNTED);
      }, duration);
    }

    if (status === STATUS.UNMOUNTED) {
      if (typeof onTransitionExitFinished === 'function') {
        onTransitionExitFinished();
      }
    }
  }, [
    status,
    setStatus,
    duration,
    onTransitionEnterStarted,
    onTransitionEnterFinished,
    onTransitionExitStarted,
    onTransitionExitFinished
  ]);

  useLayoutEffect(() => {
    if ([STATUS.UNMOUNTED, STATUS.START_TO_EXIT, STATUS.EXITING].includes(status) && mounted) {
      clearTimeout(timeoutIdRef.current);

      setStatus(STATUS.START_TO_ENTER);
    }

    if ([STATUS.ENTERED, STATUS.START_TO_ENTER, STATUS.ENTERING].includes(status) && !mounted) {
      clearTimeout(timeoutIdRef.current);

      setStatus(STATUS.START_TO_EXIT);
    }
  }, [mounted, status, setStatus]);

  if (status === STATUS.UNMOUNTED) {
    return null;
  }

  return (
    <Ref
      $ref={childNode => {
        if (!childNode || !childNode.removeAttribute) {
          return;
        }

        targetRef.current = childNode;

        const attributesToBeAdded = getTransitionAtrributes(status);

        const attributesToBeRemoved = [
          'data-transition-enter',
          'data-transition-entering',
          'data-transition-entered',
          'data-transition-exit',
          'data-transition-exiting'
        ].filter(key => !attributesToBeAdded.includes(key));

        attributesToBeRemoved.forEach(dataKey => {
          childNode.removeAttribute(dataKey);
        });

        attributesToBeAdded.forEach(attribute => {
          childNode.setAttribute(attribute, true);
        });
      }}
    >
      {children}
    </Ref>
  );
};

Transition.propTypes = {
  /** The transition duration in ms */
  duration: PropTypes.number,
  /** Controls whether the wrapped component should be rendered or not */
  mounted: PropTypes.bool,
  /** This event is triggered, when wrapped component starts the enter transition */
  onTransitionEnterStarted: PropTypes.func,
  /** This event is triggered, when wrapped component finishes the enter transition */
  onTransitionEnterFinished: PropTypes.func,
  /** This event is triggered, when wrapped component starts the exit transition */
  onTransitionExitStarted: PropTypes.func,
  /** This event is triggered,  when wrapped component finishes the exit transition */
  onTransitionExitFinished: PropTypes.func
};

export default Transition;
