import React, {ComponentType} from 'react';

import {EvergreenTranslationsContext} from './provider';
import {TranslationContextType} from './types';
import {createTFunction} from './helpers';
import {DEFAULT_LOCALE, LOCALES} from './constants';

let isMisuageWarningShown = false;
const isProduction = window.process && window.process.env && window.process.env.NODE_ENV === 'production';
const isTest = window.process && window.process.env && window.process.env.NODE_ENV === 'test';

interface WithTranslationsProps {
  __evergreenTranslations?: TranslationContextType;
}

/**
 * This HOC is used to inject the translations context into a component,
 * it is suggested to be used in class components only.
 *
 * For functional components, use the `useEvergreenTranslations` hook instead.
 *
 * @param WrappedComponent
 * @returns
 */
export const withTranslations = <P extends object>(WrappedComponent: ComponentType<P>) => {
  class WithTranslations extends React.Component<Omit<P, keyof WithTranslationsProps>> {
    static displayName: string;

    render() {
      return (
        <EvergreenTranslationsContext.Consumer>
          {value => {
            if (!value && !isProduction && !isTest && !isMisuageWarningShown) {
              // eslint-disable-next-line no-console
              console.error('withTranslations must be used within a EvergreenTranslationsProvider');
              isMisuageWarningShown = true;
            }

            return (
              <WrappedComponent
                {...(this.props as P)}
                // If the provider is not found, we provide a default t function (useful for testing)
                __evergreenTranslations={value ? value : {t: createTFunction(LOCALES[DEFAULT_LOCALE])}}
              />
            );
          }}
        </EvergreenTranslationsContext.Consumer>
      );
    }
  }

  WithTranslations.displayName = `WithTranslations(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithTranslations;
};

/**
 * This HOC is used to inject the translations context into a component,
 * it is suggested to be used in class components only. This version of the HOC
 * provides a ref to the wrapped component.
 *
 * For functional components, use the `useEvergreenTranslations` hook instead.
 *
 * @param WrappedComponent
 * @returns
 */
export const withTranslationsRef = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithTranslations = React.forwardRef((props: P, ref) => (
    <EvergreenTranslationsContext.Consumer>
      {value => {
        if (!value && !isProduction && !isTest && !isMisuageWarningShown) {
          // eslint-disable-next-line no-console
          console.error('withTranslations must be used within a EvergreenTranslationsProvider');
          isMisuageWarningShown = true;
        }

        return (
          <WrappedComponent
            {...props}
            // If the provider is not found, we provide a default t function (useful for testing)
            __evergreenTranslations={value ? value : {t: createTFunction(LOCALES[DEFAULT_LOCALE])}}
            ref={ref}
          />
        );
      }}
    </EvergreenTranslationsContext.Consumer>
  ));

  WithTranslations.displayName = `WithTranslationsRef(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithTranslations;
};
