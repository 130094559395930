import React from 'react';
import Skeleton from '../component';
import SkeletonBody from './body';

export type SkeletonInputProps = React.HTMLAttributes<HTMLDivElement> & {
  heading?: boolean;
};

const SkeletonInput = ({heading, ...props}: SkeletonInputProps) => {
  return (
    <div {...props}>
      {heading && <SkeletonBody style={{marginBottom: '4px'}} />}
      <Skeleton width="100%" height="44px" darker={false} />
    </div>
  );
};

SkeletonInput.displayName = 'SkeletonInput';

export default SkeletonInput;
