import compact from 'lodash/compact';

let env = 'development';

const domain = (
  h = window && window.location && window.location.host,
  m = window && window.document && window.document.head.querySelector('meta[name="domain"]')
) => {
  if (m && m.content) {
    return m.content;
  }

  const d = (h && h.split(':')[0]) || '';
  const host = d === 'localhost' ? [undefined, 'localhost'] : h.split('.');
  const [, _domain, ext = ''] = host;
  return compact([_domain, ext]).join('.');
};

export const config = host => {
  switch (domain(host)) {
    case 'workable.com':
      return import(/* webpackMode: "eager", webpackChunkName: "config" */ './production');
    case 'localhost':
      env = __ENV__ === 'e2e' ? 'e2e' : 'development';
      break;
    case 'officedroid.com':
      env = 'officedroid';
      break;
    case 'workablestg3.com':
      env = 'stg3';
      break;
    case 'workablestg5.com':
      env = 'stg5';
      break;
    case 'workablestg10.com':
      env = 'stg10';
      break;
    case 'workabledemostg.com':
      env = 'demostg';
      break;
    case 'workabledemo.com':
      env = 'demo';
      break;
    default:
      env = __ENV__;
  }
  return import(
    /* webpackInclude: /\.js$/, webpackExclude: /\.test\.js$/, webpackMode: "lazy-once", webpackChunkName: "config" */
    `./${env}`
  );
};

export default config();
