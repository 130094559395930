const propsFilter = props => {
  const customMatchers = [];
  let filteredProps = {};

  const isFiltered = prop => {
    const existsInFilteredProps = filteredProps[prop] && props[prop] === filteredProps[prop];
    if (existsInFilteredProps) {
      return true;
    }
    return customMatchers.some(matcher => matcher.test(prop));
  };

  return {
    like(regex) {
      if (regex instanceof RegExp === false) {
        throw new Error('Expected a regex in props filter#like');
      }
      customMatchers.push(regex);
      return this;
    },

    dataAttributes() {
      customMatchers.push(/^data\-/);
      return this;
    },

    ariaAttributes() {
      customMatchers.push(/^aria\-/);
      return this;
    },

    styles() {
      filteredProps.style = props.style;
      filteredProps.className = props.className;
      return this;
    },

    getFiltered() {
      const result = Object.keys(props).reduce((acc, prop) => {
        if (isFiltered(prop)) {
          acc[prop] = props[prop];
        }
        return acc;
      }, filteredProps);
      return {...result};
    },

    excludeFiltered() {
      return Object.keys(props).reduce((acc, prop) => {
        if (isFiltered(prop)) {
          return acc;
        }

        acc[prop] = props[prop];
        return acc;
      }, {});
    },

    reset() {
      filteredProps = {};
      customMatchers.length = 0;
    }
  };
};

export default propsFilter;
