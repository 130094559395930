import PropTypes from 'prop-types';

export default {
  /** Renders a disabled button. User is unable to click it */
  disabled: PropTypes.bool,
  /** A URL or URI. When this prop passed, a link masked as button is rendered instead. */
  href: PropTypes.string,
  /** TODO:To be documented */
  loading: PropTypes.bool,
  /** Along with `href` property used to set the `rel` attribute of an anchor */
  rel: PropTypes.string,
  /** Wrapped on a flex container the button will get 100% of it's parent container width */
  responsive: PropTypes.bool,
  /** Passing this property renders a shorter button */
  small: PropTypes.bool,
  /** Along with `href` property used to set the `target` attribute of an anchor */
  target: PropTypes.string,
  /** Sets the type attribute of the <button> element which defines its behavior inside a <form> as per HTML5 */
  type: PropTypes.oneOf(['reset', 'submit', 'button'])
};
