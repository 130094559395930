import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Ref from '../ref';
import KeyboardHandler from 'shared/ui/behaviors/keyboardHandler';
import CSSTransition from 'shared/ui/behaviors/cssTransition';
import symbols from 'shared/ui/symbols';

export const focusAllowedSelectors = [
  'a[href]',
  'area[href]',
  'button:not([disabled])',
  'embed',
  'iframe',
  'input:not([disabled])',
  'object',
  'select:not([disabled])',
  'textarea:not([disabled])',
  '[tabindex]',
  '[contenteditable]'
].join(', ');

const giveInitialFocus = focusables => {
  const first = focusables[0];
  const priorityFocusable =
    focusables.find(node => node.hasAttribute('data-autofocus') || node.hasAttribute('autofocus')) || first;

  if (priorityFocusable) {
    priorityFocusable.focus();
  }
};

const trapFocus = (allowedFocusables, event, {shiftKey}) => {
  const {length: $length, 0: firstCanFocus, [$length - 1]: lastCanFocus} = allowedFocusables;

  if (!$length) {
    return;
  }

  if (shiftKey) {
    if (document.activeElement === firstCanFocus) {
      event.preventDefault();
      lastCanFocus.focus();
    }
  } else if (document.activeElement === lastCanFocus) {
    event.preventDefault();
    firstCanFocus.focus();
  }
};

const FocusTrap = ({trapEnabled = true, children, ...props}) => {
  const ref = useRef({hasGivenInitialFocus: false});
  const child = React.Children.only(children);
  let focusableElements = [];

  return (
    (trapEnabled && (
      <Ref
        $ref={childNode => {
          if (!childNode || !trapEnabled) {
            return;
          }

          focusableElements = [...childNode.querySelectorAll(focusAllowedSelectors)];
        }}
      >
        <CSSTransition
          handleCompleted={() => {
            if (!ref.current.hasGivenInitialFocus) {
              giveInitialFocus(focusableElements);
              ref.current.hasGivenInitialFocus = true;
            }
          }}
        >
          <KeyboardHandler
            handleTabPressed={trapEnabled ? (...params) => trapFocus(focusableElements, ...params) : () => undefined}
          >
            {React.cloneElement(child, props)}
          </KeyboardHandler>
        </CSSTransition>
      </Ref>
    )) ||
    React.cloneElement(child, props)
  );
};

FocusTrap[symbols.Behavior] = true;

FocusTrap.propTypes = {
  /** The trap is disabled if false */
  trapEnabled: PropTypes.bool,
  /** A single child */
  children: PropTypes.element.isRequired,
  ...KeyboardHandler.propTypes
};

/** For testing purposes */
export {trapFocus, giveInitialFocus};

export default FocusTrap;
