import texts from './texts.json';
import setDefaultDynamicTexts from 'shared/ui/helpers/locales/setDefaultDynamicTexts';

export default Object.freeze(
  setDefaultDynamicTexts(texts, {
    cookieConsent: {
      text: ({companyName}) =>
        `${companyName} uses cookies to improve your experience on our site. Before you continue, let us know if you’re happy to accept the use of cookies, in accordance with our`
    }
  })
);
