import React, {createContext, useMemo, useCallback, useEffect} from 'react';
import merge from 'lodash/merge';

import {DEFAULT_LOCALE, LOCALES} from './constants';
import {LocaleContextType, TranslationContextType, EvergreenTranslationsProviderProps, LocaleTexts} from './types';
import {createTFunction, getConvertedLocale} from './helpers';

export const EvergreenTranslationsContext = createContext<TranslationContextType | null>(null);
export const EvergreenLocaleContext = createContext<LocaleContextType | null>(null);

export const EvergreenTranslationsProvider = ({
  children,
  overrides,
  locale: _locale = DEFAULT_LOCALE
}: EvergreenTranslationsProviderProps) => {
  const locale = getConvertedLocale(_locale);

  const database = useMemo(() => merge({}, LOCALES, overrides), [overrides]);
  const mergedTranslations = useMemo(() => merge({}, database[DEFAULT_LOCALE], database[locale]), [database, locale]);

  const t = useCallback(
    (path: string, _overrides?: LocaleTexts) => createTFunction(mergedTranslations)(path, _overrides),
    [mergedTranslations]
  );

  const translationsValue = useMemo(() => ({t}), [t]);
  const localeValue = useMemo(() => ({locale}), [locale]);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('evergreen-translations-locale-change', {detail: {locale}}));
  }, [locale]);

  return (
    <EvergreenTranslationsContext.Provider value={translationsValue}>
      <EvergreenLocaleContext.Provider value={localeValue}>{children}</EvergreenLocaleContext.Provider>
    </EvergreenTranslationsContext.Provider>
  );
};
