import React from 'react';
import {isIE} from 'react-device-detect';
import applyCssVariablesToRoot from 'shared/ui/helpers/applyCssVariablesToRoot';
import generateColorVariables from 'shared/ui/helpers/colors/generateVariables';
import {meta} from 'candidate/config';

const noop = () => undefined;

const Theme = React.memo(({_cb = noop, _isIE = isIE, cssvariables, theme = meta.themeColor}) => {
  React.useEffect(() => {
    if (!theme || !theme.startsWith('#') || theme.length !== 7) {
      return;
    }

    const variables = {...(cssvariables ? cssvariables : {}), ...generateColorVariables({'color-default': theme})};
    applyCssVariablesToRoot(variables);

    if (_isIE) {
      import(
        /* webpackMode: "lazy", webpackChunkName: "css-vars-ponyfill" */
        'css-vars-ponyfill'
      )
        .then(({default: polyfill}) => polyfill({variables}))
        .then(_cb);
    }
  }, [theme, cssvariables]);

  return null;
});

export default Theme;
