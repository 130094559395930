import {$Symbol} from 'shared/ui/symbols';

export const Device = Object.freeze({
  Desktop: 'desktop',
  Mobile: 'mobile'
});

const mediaQueriesBuilderSymbol = $Symbol('MediaQueries');

/**
 * The builder of media queries.
 *
 * addQueryWithSpecificity: (device: string, query: string[])
 * All the supported queries for each device should be added with `addQueryWithSpecificity` method.
 * The `device` is the string will be returned when the specified `query` mathes.
 * BE CAREFUL: The queries of each device should be chained in order from the smallest viewport to the largest.
 *
 * setLargerDevice: (device: string, query?: string[])
 * LargerDevice is the fallback device which matches when none of the devices defined with `addQueryWithSpecificity` match.
 * In the same way, the `device` is the string will be returned as fallback device. No need to define `query` param as it matches `all`.
 *
 * getQueries: ()
 * It is called internally at detectDevice() and returns all the queries.
 * Should not be called with builder that is passed as param to detectDevice.
 *
 */
export const mediaQueriesBuilder = () => {
  const queries = new Set();

  let largerDevice;
  let largerDeviceQuery;

  const builder = {
    addQueryWithSpecificity: (device, query) => {
      queries.add({device, query});
      return builder;
    },
    setLargerDevice: (device, query = ['all']) => {
      largerDevice = device;
      largerDeviceQuery = query;
      return builder;
    },
    getQueries: () => {
      if (!largerDevice) {
        throw new Error('Tha largerDevice should be defined at `mediaQueriesBuilder` with `setLargerDevice` method.');
      }

      queries.add({device: largerDevice, query: largerDeviceQuery});
      return queries;
    }
  };

  builder[mediaQueriesBuilderSymbol] = true;

  return builder;
};

const DEFAULT_MEDIA_QUERIES = mediaQueriesBuilder()
  .setLargerDevice(Device.Desktop)
  .addQueryWithSpecificity(Device.Mobile, [
    'only screen and (max-width: 766px) and (orientation: landscape)',
    'only screen and (max-width: 766px) and (orientation: portrait)'
  ]);

const detectDevice = (_mediaQueriesBuilder = DEFAULT_MEDIA_QUERIES) => {
  if (!_mediaQueriesBuilder[mediaQueriesBuilderSymbol]) {
    throw new Error('Param of `detectDevice` should be created with `mediaQueriesBuilder`.');
  }

  const mediaQueries = _mediaQueriesBuilder.getQueries();

  let matchingDevice;

  for (const {device, query} of mediaQueries) {
    const matches = query.find(q => window.matchMedia?.(q).matches);

    if (matches) {
      matchingDevice = device;
      break;
    }
  }

  return matchingDevice || Device.Desktop;
};

export default detectDevice;
