import React from 'react';
import Loadable from 'react-loadable';

const loading = id => () => <svg id={id} />;

const noop = () => undefined;

export const load = svg =>
  svg
    .then(({default: {id}}) => props => (
      <svg {...props}>
        <use xlinkHref={`#${id}`} />
      </svg>
    ))
    .catch(noop);

const svg = ({importFn, name}) => Loadable({loader: () => importFn, loading: loading(name)});

export const makeNotFoundSvg = () =>
  svg({
    importFn: load(import(/*  webpackChunkName: "404.svg" */ './notFound.svg?sprite')),
    name: '404.svg?sprite'
  });

export const makeOfflineSvg = () =>
  svg({
    importFn: load(import(/*  webpackChunkName: "offline.svg" */ './offline.svg?sprite')),
    name: 'offline.svg?sprite'
  });

export const makeServerErrorSvg = () =>
  svg({
    importFn: load(import(/*  webpackChunkName: "500.svg" */ './serverError.svg?sprite')),
    name: '500.svg?sprite'
  });
