import qs from 'query-string';

const extendedURL = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[.\!\/\\w]*))?)/gi; // eslint-disable-line max-len

const parseURL = (url = '') => {
  const parser = global.document.createElement('a');
  const regexp = new RegExp(extendedURL);

  if (!regexp.test(url)) {
    return {};
  }

  parser.href = url;

  return {
    domain: parser.hostname.split('.')[0],
    hash: parser.hash,
    host: parser.host,
    hostname: parser.hostname,
    origin: parser.origin,
    original: url,
    pathname: parser.pathname,
    port: parser.port,
    protocol: parser.protocol,
    querystring: qs.parse(parser.search || '') || {},
    search: parser.search
  };
};

export default parseURL;
