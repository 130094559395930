import getContainingBlockElement from './getContainingBlockElement';
import getDocumentHeight from './getDocumentHeight';

/**
 * Returns the height of document
 * If the element has a containing block, it returns the height of the containing block.
 *
 * @param {HTMLElement} element
 * @returns {number} `number`
 */
const getContainingBlockHeight = element => {
  const containingBlockElement = getContainingBlockElement(element);

  if (containingBlockElement) {
    return containingBlockElement.clientHeight;
  }

  return getDocumentHeight();
};

export default getContainingBlockHeight;
