import en from 'shared/ui/locales/en';
import el from 'shared/ui/locales/el';
import de from 'shared/ui/locales/de';
import es from 'shared/ui/locales/es';
import fr from 'shared/ui/locales/fr';
import pt from 'shared/ui/locales/pt';

import {LocaleTexts} from './types';

export const LOCALES = {
  'en-US': en as LocaleTexts,
  'el-GR': el as LocaleTexts,
  'de-DE': de as LocaleTexts,
  'es-ES': es as LocaleTexts,
  'fr-FR': fr as LocaleTexts,
  'pt-PT': pt as LocaleTexts
} as const;

export const LOCALE_SHORTHANDS = {
  en: 'en-US',
  el: 'el-GR',
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-FR',
  pt: 'pt-PT'
} as const;

export const DEFAULT_LOCALE = 'en-US';
