import {useLayoutEffect} from 'react';

import ResizeObserver from 'resize-observer-polyfill';
import BodyWidthObserver from 'shared/ui/helpers/bodyWidthObserver';

export default function useDropdownLayoutMutation({dialogElement, targetElement, freeze, onMutation, onTriggerHidden}) {
  useLayoutEffect(() => {
    const frames = [];

    const update = () => {
      frames.push(window.requestAnimationFrame(onMutation));
    };

    /**
     * Watch for size changes in the body to update the dialog position
     * This takes care of the case where the body width changes due to a scrollbar appearing or disappearing
     */
    const bodyWidthObserver = new BodyWidthObserver(update);

    /**
     * Watch for size changes in the trigger element to update the dialog position
     */
    const resizeObserver = new ResizeObserver(update);

    /**
     * Watch when the trigger element is hidden
     */
    let intersectionObserver;

    if (typeof onTriggerHidden === 'function') {
      intersectionObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              return;
            }

            onTriggerHidden?.();
          });
        },
        {threshold: 0}
      );
    }

    /**
     * Watch for position changes of the trigger element to update the dialog position
     */
    const mutationObserver = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (
          mutation.attributeName === 'style' &&
          (mutation.target.style.top ||
            mutation.target.style.left ||
            mutation.target.style.right ||
            mutation.target.style.bottom)
        ) {
          update();
        }
      });
    });

    onMutation();

    if (!freeze && targetElement && dialogElement) {
      bodyWidthObserver.observe();
      resizeObserver.observe(targetElement);
      resizeObserver.observe(dialogElement);
      intersectionObserver?.observe(targetElement);
      mutationObserver.observe(targetElement, {
        attributes: true,
        attributeFilter: ['style']
      });

      const parentDialog = targetElement.closest('dialog[data-evergreen-dialog="true"]');
      if (parentDialog) {
        resizeObserver.observe(parentDialog);
      }

      window.addEventListener('resize', onMutation);
      window.addEventListener('scroll', onMutation, true);
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
      bodyWidthObserver.disconnect();
      intersectionObserver?.disconnect();

      window.removeEventListener('resize', onMutation);
      window.removeEventListener('scroll', onMutation, true);

      frames.forEach(window.cancelAnimationFrame);
    };
  }, [dialogElement, targetElement, freeze, onMutation, onTriggerHidden]);
}
