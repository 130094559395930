/**
 * @fileoverview This file contains the functions to handle the consent for Google Tag Manager.
 * The functions are used to update the consent for Google Tag Manager and to initialize the consent.
 * The consent stored in Hubspot cookies is the one used to update the consent for Google Tag Manager.
 *
 * More info regarding Google Consent Mode:
 * (here)[https://support.google.com/analytics/answer/9976101]
 * and (here)[https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced]
 */

import {getCookie, setCatPrefCookieValue} from 'shared/ui/organisms/cookieConsent/helpers';
import {CAT_PREF_COOKIE_NAME, OPT_OUT_COOKIE_NAME} from 'shared/ui/organisms/cookieConsent/constants';

window.dataLayer = window.dataLayer || [];

const defaultConsent = {
  advertisementEnabled: false,
  functionalityEnabled: false,
  analyticsEnabled: false
};

function gtag() {
  window.dataLayer.push(arguments);
}

/**
 * @typedef {Object} Consent
 * @property {boolean} advertisementEnabled
 * @property {boolean} functionalityEnabled
 * @property {boolean} analyticsEnabled
 */

/**
 * Maps the consent object to the gtag consent object.
 * Consent mode reference: https://support.google.com/tagmanager/answer/13802165
 * @param {Consent} consent - The consent object.
 * @returns {Object} The gtag consent object.
 */
const mapConsentToGtagConsent = ({advertisementEnabled, analyticsEnabled, functionalityEnabled} = defaultConsent) => ({
  ad_storage: advertisementEnabled ? 'granted' : 'denied',
  ad_user_data: advertisementEnabled ? 'granted' : 'denied',
  ads_data_redaction: advertisementEnabled ? 'denied' : 'granted',
  ad_personalization: advertisementEnabled ? 'granted' : 'denied',
  personalization_storage: advertisementEnabled ? 'granted' : 'denied',
  analytics_storage: analyticsEnabled ? 'granted' : 'denied',
  functional_storage: functionalityEnabled ? 'granted' : 'denied',
  security_storage: functionalityEnabled ? 'granted' : 'denied'
});

const mapHubspotCookiesToGtagConsent = () => {
  const oldOptOutCookie = getCookie(OPT_OUT_COOKIE_NAME);
  const categoryPreferencesCookie = getCookie(CAT_PREF_COOKIE_NAME);
  const isPreferenceEnabled = preference => preference.split(':')?.[1] === 'true';

  if (categoryPreferencesCookie) {
    const [analyticsEnabled, advertisementEnabled, functionalityEnabled] = categoryPreferencesCookie
      .split(',')
      .map(isPreferenceEnabled);

    return mapConsentToGtagConsent({
      advertisementEnabled,
      functionalityEnabled,
      analyticsEnabled
    });
  }

  if (oldOptOutCookie === 'no') {
    return mapConsentToGtagConsent({
      advertisementEnabled: true,
      functionalityEnabled: true,
      analyticsEnabled: true
    });
  }

  return mapConsentToGtagConsent(defaultConsent);
};

/**
 * https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#redact_ads_data
 * When ad_storage is denied, new cookies will not be set for advertising purposes.
 * The ads_data_redaction parameter will have no effect when ad_storage is granted.
 * @param {*} gtagConsent
 */
const handleAdDataRedaction = gtagConsent => {
  if (gtagConsent.ad_storage === 'denied' || gtagConsent.ad_user_data === 'denied') {
    gtag('set', 'ads_data_redaction', true);
  }
};

export const initializeGtmConsent = () => {
  const consent = mapHubspotCookiesToGtagConsent();

  gtag('consent', 'default', consent);
  handleAdDataRedaction(consent);
};

/**
 * @param {Consent} consent
 */
export const updateConsent = consent => {
  const {analyticsEnabled, advertisementEnabled, functionalityEnabled} = consent;
  const gtagUpdatedConsent = mapConsentToGtagConsent(consent);
  gtag('consent', 'update', gtagUpdatedConsent);
  handleAdDataRedaction(gtagUpdatedConsent);
  setCatPrefCookieValue([analyticsEnabled, advertisementEnabled, functionalityEnabled]);
};

export const updateConsentFromCookie = () => {
  const gtagConsentCookie = mapHubspotCookiesToGtagConsent();
  gtag('consent', 'update', gtagConsentCookie);
  handleAdDataRedaction(gtagConsentCookie);
};
