export const DIALOG_ROLES = Object.freeze({
  // Dialog
  Content: 'dialog-content',

  // Modal
  Wrapper: 'modal-wrapper',
  Header: 'dialog-header',
  HeaderSlot: 'dialog-header-slot',
  Title: 'dialog-title',
  Container: 'dialog-container',
  ContentContainer: 'dialog-content-container',
  Actions: 'dialog-actions',

  // Backdrop
  Backdrop: 'backdrop',

  // Drawer
  Drawer: 'drawer',

  // FullscreenModal
  Subtitle: 'dialog-subtitle',
  ContentAndNotifications: 'dialog-content-and-notifications',
  ContentWrapper: 'dialog-content-wrapper',
  ContentArea: 'dialog-content-area',
  Navigation: 'dialog-navigation',
  MobileNavigation: 'dialog-mobile-navigation',
  Notifications: 'dialog-notifications',

  // FullscreenModal - Steps
  StepsContainer: 'steps-container',
  Step: 'step',
  StepIcon: 'step-icon',
  StepperLabel: 'stepper-label',
  MobileStepper: 'mobile-stepper'
});
