/* eslint-disable react/jsx-no-constructed-context-values */
import React, {useCallback, useState} from 'react';
import qs from 'query-string';
import {withRouter} from 'react-router';
import {EvergreenTranslationsProvider} from 'shared/ui/providers/translations';
import i18n from 'candidate/i18n';
import Locale from './careersLocale';

export const CareersLocaleProvider = ({children, history, location}) => {
  const [locale, setLocale] = useState(i18n.language);

  const setCareersLocale = useCallback(lng => {
    if (!lng) {
      return;
    }

    if (history && history.replace && location) {
      const parsed = qs.parse(location.search || '');
      history.replace({search: qs.stringify({...(parsed || {}), lng})});
    }

    i18n.changeLanguage(lng).then(() => setLocale(lng));
  });

  return (
    <EvergreenTranslationsProvider locale={locale}>
      <Locale.Provider value={{locale, setCareersLocale}}>
        {children instanceof Function ? children({locale, setCareersLocale}) : children}
      </Locale.Provider>
    </EvergreenTranslationsProvider>
  );
};

export default withRouter(CareersLocaleProvider);
