export const getCookieHostName = () => {
  const hostname = location.hostname;

  if (hostname === 'localhost') {
    return hostname;
  }

  // Skip the first subdomain if there is at least one subdomain
  const matchedHostnameToCookieName = hostname.match(/((?!\.co\.uk)\.[\w\-.]+){2}(\.co\.uk)?$/g);

  if (matchedHostnameToCookieName) {
    return matchedHostnameToCookieName[0];
  }

  return `.${hostname}`;
};

export const createCookieString = (name, value, {getDomain = getCookieHostName} = {}) => {
  const now = new Date(Date.now());
  const expirationDate = new Date(Date.now());
  expirationDate.setMonth(now.getMonth() + 13);
  const expirationInSeconds = expirationDate.getTime() - now.getTime();

  return `${name}=${value};expires=${expirationDate.toUTCString()};max-age=${expirationInSeconds};domain=${getDomain()};path=/`;
};
