import React from 'react';

export default ({caption, className, error}) => {
  if (error) {
    throw new Error(error);
  }

  let classNames = 'bullets-loader-container';

  if (className) {
    classNames = classNames + ` ${className}`;
  }

  return (
    <div className={classNames}>
      <div className="bullets-loader bullets-loader--centered">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        {caption ? <span className="bullets-loader__msg">{caption}</span> : null}
      </div>
    </div>
  );
};
