import React from 'react';
import clsx from "clsx";
import BaseText from '../base';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const Subheading = ({children, ...props}) => (
  <BaseText as="h4" {...props} className={clsx(styles.subheading, props.className)} defaultFlavor="secondary" skeletonHeight="18px">
    {children}
  </BaseText>
);

Subheading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  success: PropTypes.bool,
  caution: PropTypes.bool,
  danger: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  /**
   * @deprecated use 'primary' instead
   */
  emphasis: PropTypes.bool,
  /**
   * @deprecated use 'secondary' instead
   */
  muted: PropTypes.bool,
  /**
   * @deprecated use 'secondary' instead
   */
  neutral: PropTypes.bool,
  color: PropTypes.string,
  id: PropTypes.string
};

Subheading.displayName = 'Text.Subheading';

export default Subheading;
