/* eslint-disable react/jsx-no-constructed-context-values */
import React, {Component} from 'react';
import api from 'candidate/api';
import {meta} from 'candidate/config';
import featurePolicy from 'candidate/featuresPolicy';
import Account from 'candidate/providers/account';
import CareersLocale from 'candidate/providers/locale/careers';
import AccountDetails from './details';

export class AccountDetailsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {accountDetails: undefined, error: props.error};
    this.updateAccountDetails = this.updateAccountDetails.bind(this);
    this.fetchAccountDetails = this.fetchAccountDetails.bind(this);
    this.isLanguagePackEnabled = this.isLanguagePackEnabled.bind(this);
  }

  componentDidMount() {
    this.fetchAccountDetails();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.account !== this.props.account) {
      this.fetchAccountDetails();
    }
  }

  fetchAccountDetails() {
    const {account} = this.props;
    if (!account) {
      return;
    }

    const current = this.state.accountDetails || {};
    if (account && current.subdomain !== account) {
      this.setState({accountDetails: undefined, error: undefined}, () =>
        api
          .account(account)
          .details()
          .then(accountDetails => this.updateAccountDetails({hasCustomDomain: meta.hasCustomDomain, ...accountDetails}))
          .catch(error => this.setState({accountDetails: undefined, error}))
      );
    }
  }

  isLanguagePackEnabled() {
    const {languages} = this.state.accountDetails;
    const isI18nEnabled = featurePolicy().allows('acpi18n');

    return isI18nEnabled && languages?.options?.length > 1;
  }

  updateAccountDetails(accountDetails) {
    this.setState({accountDetails, error: undefined});
  }

  render() {
    const {accountDetails, error} = this.state;
    if (error) {
      throw error;
    }

    return (
      <AccountDetails.Provider
        value={{
          accountDetails,
          isLanguagePackEnabled: this.isLanguagePackEnabled,
          updateAccountDetails: this.updateAccountDetails
        }}
      >
        {this.props.children instanceof Function
          ? this.props.children({
              accountDetails,
              isLanguagePackEnabled: this.isLanguagePackEnabled,
              updateAccountDetails: this.updateAccountDetails
            })
          : this.props.children || null}
      </AccountDetails.Provider>
    );
  }
}

export default props => (
  <CareersLocale.Consumer>
    {({changeLanguage}) => (
      <Account.Consumer>
        {account => <AccountDetailsProvider account={account} changeLanguage={changeLanguage} {...props} />}
      </Account.Consumer>
    )}
  </CareersLocale.Consumer>
);
