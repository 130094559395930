import React from 'react';
import Loadable from 'react-loadable';
import Loader from 'candidate/shared/loader';
import {
  CAREERS_PREVIEW,
  FORM_PREVIEW,
  GDPR_PREVIEW,
  JOB_PREVIEW,
  OOPS,
  OOPS_WITHOUT_SLASH,
  SURVEY_PREVIEW
} from 'candidate/shared/paths';

require('./styles.scss');

export const Form = Loadable({
  loader: () => import(/* webpackChunkName: "formPreview" */ './form/dynamic'),
  loading: () => <Loader />
});

export const Job = Loadable({
  loader: () => import(/* webpackChunkName: "jobPreview" */ './job/dynamic'),
  loading: () => <Loader />
});

export const Careers = Loadable({
  loader: () => import(/* webpackChunkName: "careersPreview" */ './careers/dynamic'),
  loading: () => <Loader />
});

export const Gdpr = Loadable({
  loader: () => import(/* webpackChunkName: "gdprPreview" */ './gdpr/dynamic'),
  loading: () => <Loader />
});

export const Survey = Loadable({
  loader: () => import(/* webpackChunkName: "surveyPreview" */ './survey/dynamic'),
  loading: () => <Loader />
});

export const Oops = Loadable({
  loader: () => import(/* webpackChunkName: "oopsComponent" */ '../errors/oops'),
  loading: () => <Loader />
});

export default (href = window.location.href) => {
  if (!href) {
    return undefined;
  }

  if (href.endsWith(OOPS_WITHOUT_SLASH) || href.endsWith(OOPS)) {
    return <Oops />;
  }

  if (href.endsWith(FORM_PREVIEW)) {
    document.documentElement.classList.add('inside-iframe');
    return <Form />;
  }

  if (href.endsWith(JOB_PREVIEW)) {
    document.documentElement.classList.add('inside-iframe');
    return <Job />;
  }

  if (href.endsWith(CAREERS_PREVIEW)) {
    document.documentElement.classList.add('inside-iframe');
    return <Careers />;
  }

  if (href.endsWith(GDPR_PREVIEW)) {
    document.documentElement.classList.add('inside-iframe');
    return <Gdpr />;
  }

  if (href.endsWith(SURVEY_PREVIEW)) {
    document.documentElement.classList.add('inside-iframe');
    return <Survey />;
  }

  return undefined;
};
