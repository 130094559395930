import {useContext, useMemo} from 'react';

import {EvergreenLocaleContext, EvergreenTranslationsContext} from './provider';
import {DynamicText, Locale, LocaleTexts} from './types';
import {createTFunction} from './helpers';
import {DEFAULT_LOCALE, LOCALES} from './constants';

let isMisuageWarningShown = false;
const isProduction = window.process && window.process.env && window.process.env.NODE_ENV === 'production';
const isTest = window.process && window.process.env && window.process.env.NODE_ENV === 'test';

const fallbackContext = {t: createTFunction(LOCALES[DEFAULT_LOCALE])};

/**
 * This hook is used to get the translations for the Evergreen UI components.
 * It should only be used within Evergreen components.
 *
 * @param path
 * @param overrides
 * @returns
 */
export function useEvergreenTranslations<T = string | LocaleTexts | DynamicText>(
  path: string,
  overrides?: LocaleTexts
): T {
  const context = useContext(EvergreenTranslationsContext);

  if (!context && !isProduction && !isTest && !isMisuageWarningShown) {
    // eslint-disable-next-line no-console
    console.error('useEvergreenTranslations must be used within a EvergreenTranslationsProvider');
    isMisuageWarningShown = true;
  }

  // If the provider is not found, we provide a default t function (useful for testing)
  const {t} = context ? context : fallbackContext;

  const result = useMemo(() => t(path, overrides), [t, path, overrides]);

  return result as T;
}

/**
 * This hook can be used to get the current locale.
 */
export function useEvergreenLocale(): {locale: Locale} {
  const context = useContext(EvergreenLocaleContext);

  if (!context) {
    throw new Error('useEvergreenLocale must be used within a EvergreenTranslationsProviderProps');
  }

  const {locale} = context;

  return {
    locale
  };
}
