import React from 'react';

const noop = () => undefined;

export default React.createContext({
  addFilter: noop,
  clearAll: noop,
  removeFilter: noop,
  search: {
    filters: {
      department: [],
      location: [],
      remote: [],
      workplace: [],
      worktype: []
    },
    pending: false,
    query: ''
  },
  setFilters: noop,
  updateQuery: noop
});
