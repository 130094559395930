import ReactDOM from 'react-dom';
import clsx from "clsx";
import React from 'react';
import Transition from 'shared/ui/behaviors/transition';
import {DIALOG_ROLES} from '../constants';

import styles from './styles.scss';

const TRANSITION_DURATION = 300;

const Backdrop = React.forwardRef(({onClick, open, className, transparent}, ref) => {
  return (
    (<Transition duration={TRANSITION_DURATION} mounted={open}>
      <div
        open
        className={clsx({
          [styles.backdrop]: true,
          [styles.transparent]: transparent
        }, className)}
        ref={ref}
        onClick={onClick}
        tabIndex="0"
        data-role={DIALOG_ROLES.Backdrop}
        data-evergreen-dialog-backdrop />
    </Transition>)
  );
});

export {Backdrop};

export default ({...props}) => ReactDOM.createPortal(<Backdrop {...props} />, document.body);
