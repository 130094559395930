import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BaseButton from '../base';
import propTypes from '../base/proptypes/flavored';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import symbols from 'shared/ui/symbols';
import styles from './tertiary.scss';

const ButtonTertiary = forwardRef(
  ({caution, danger, informational, neutral, muted, ai, defaultFlavor = 'default', ...props}, ref) => {
    const classNames = mapPropsToStyleNames([
      {caution, danger, informational, neutral, muted, ai, default: defaultFlavor}
    ])
      .split(' ')
      .map(name => styles[name])
      .join(' ');

    return <BaseButton {...props} ref={ref} className={clsx(classNames, props.className)} />;
  }
);

ButtonTertiary.displayName = 'Button.Tertiary';

ButtonTertiary.propTypes = {
  ...propTypes,
  informational: PropTypes.bool
};

ButtonTertiary[symbols.Button.Tertiary] = true;

export default ButtonTertiary;
