import React from 'react';
import {updateConsentFromCookie} from 'shared/helpers/analytics/gtmConsent';
import SharedCookieConsent from 'shared/ui/organisms/cookieConsent';
import {meta} from 'candidate/config';
import featurePolicy from 'candidate/featuresPolicy';
import useConfig from 'candidate/shared/hooks/useConfig';
import {isTestEnv} from 'candidate/shared/utils/env';
import {COOKIE_CONSENT_BANNER_ATTR} from './constants';

// Sets the attribute here so when/if the related GTM trigger fires
// before the component renders, it can see that a cookie consent
// banner will be added.
document.body.setAttribute(COOKIE_CONSENT_BANNER_ATTR, 'true');

const CookieConsent = () => {
  const isBlackListedEnv = isTestEnv();
  const config = useConfig();

  if (isBlackListedEnv) {
    return null;
  }

  const isGtmConfigAvailable = config?.gtm && ['auth', 'env', 'id'].every(key => Boolean(config.gtm[key]));

  if (!isGtmConfigAvailable) {
    return null;
  }

  if (!featurePolicy().allows('cookieConsent')) {
    return null;
  }

  const color = meta?.themeColor ?? undefined;

  return (
    <SharedCookieConsent
      color={color}
      cookieSettingsEnabled
      onAcceptDone={updateConsentFromCookie}
      onDeclineDone={updateConsentFromCookie}
      onSaveSettingsDone={updateConsentFromCookie}
    />
  );
};

export default CookieConsent;
