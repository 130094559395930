const getKeysFromTruthyPropValues = (props, prefix = '') => {
  const truthyProps = Object.keys(props).filter(key => {
    if (key === 'default') {
      return false;
    }
    return props[key] instanceof Function ? !!props[key]() : !!props[key];
  });
  if (truthyProps.length === 0 && props.default) {
    return `${prefix}${props.default}`;
  }
  return truthyProps.map(text => `${prefix}${text}`).join(' ');
};

/**
 * Maps props to its keys when their values are truthy. A util function
 * to be used in order to get class names for components based on prop
 * keys.
 * Example:
 * ```
 *  <Button danger/>
 *
 *  // button.js
 *  const Button = ({danger, success, neutral, enabled, disabled, ...rest}) => {
 *    const styleNames = mapPropsToStyleNames([
 *      {danger, success, neutral, default: 'neutral' },
 *      {disabled, enabled, default: 'enabled'}
 *    ]); // -> 'danger enabled'
 *    return <button className={styleNames} {...rest}/>;
 *  }
 *
 * ```
 * @param {Object|Array[Object]} props An object or an array of objects in case you need to group
 *  the keys under groups.
 * @param {String} props.default The default value under a props object to be returned if
 *  non of its values is true.
 * @returns {String} - All the prop keys whith truthy values joined together space separated.
 */
export default function mapPropsToStyleNames(props, prefix = '') {
  if (props instanceof Array) {
    return props
      .map(prop => getKeysFromTruthyPropValues(prop, prefix))
      .filter(Boolean)
      .join(' ');
  }
  return getKeysFromTruthyPropValues(props, prefix);
}
