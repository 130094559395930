import React from 'react';
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import {DIALOG_ROLES} from '../constants';
import {CLOSE_ACTION} from './constants';
import './styles.scss';

const ensureChildIsButton = child => {
  if (child && symbols.Button.includes(child.type)) {
    return true;
  }

  throw new Error('Dialog.Actions accepts only UI/Button as children');
};

const markClonedActionChild = (child, {value, ...props}) => {
  return React.cloneElement(child, {
    ...props,
    ...(value ? {value} : {})
  });
};

const processChildrenAsButtons = ({children, dismissIndex, onUnsupportedClose}) => {
  if (React.Children.count(children) === 0) {
    throw new Error('Dialog.Actions accepts at least one UI/Button as child');
  }
  const processed = [];

  React.Children.forEach(children, (child, index) => {
    if (!child) {
      return;
    }

    const props = {
      key: index
    };

    ensureChildIsButton(child);

    if (dismissIndex === index) {
      const onChildClick = child.props.onClick;
      props.onClick = (...args) => {
        if (onUnsupportedClose) {
          onUnsupportedClose(...args);
        }

        if (onChildClick) {
          onChildClick(...args);
        }
      };
      props.value = CLOSE_ACTION.DISMISS;
    }

    processed.push(markClonedActionChild(child, props));
  });

  return processed;
};

const DialogActions = ({children, dismissIndex = -1, onUnsupportedClose, ...props}) => {
  return (
    <div data-role={DIALOG_ROLES.Actions} {...props}>
      {processChildrenAsButtons({children, dismissIndex, onUnsupportedClose})}
    </div>
  );
};

DialogActions.displayName = 'Dialog.Actions';

DialogActions[symbols.Dialog.Actions] = true;

DialogActions.constants = {
  ACTION: CLOSE_ACTION
};

DialogActions.propTypes = {
  /** Accepts Button elements only */
  children: (props, propName, componentName) => {
    const prop = props[propName];

    let error = null;

    React.Children.forEach(prop, function (child) {
      if (child && !symbols.Button.includes(child.type)) {
        error = new Error('`' + componentName + '` children should be of type `Button`.');
      }
    });
    return error;
  },
  /** Zero based index of the dismiss button in passed children */
  dismissIndex: PropTypes.number,
  /** Used internally to handle close dialog on unsupported browsers. It gets wired on Dialog.Container */
  onUnsupportedClose: PropTypes.func
};

export default DialogActions;
