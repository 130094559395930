import getDocumentHeight from './getDocumentHeight';
import getDocumentWidth from './getDocumentWidth';
import getAbsoluteSize from './getAbsoluteSize';
import getBoundingBox from './getBoundingBox';

import {POSITIONS} from '../constants';

/**
 * Checks whether a DOM Element is in the viewport.
 *
 * @param {HTMLElement} element
 * @returns boolean
 */
const isElementInViewport = element => {
  const rect = getBoundingBox(element);
  return (rect.top < getDocumentHeight() && rect.top > 0) || (rect.bottom > 0 && rect.bottom < getDocumentHeight());
};

/**
 * Considering the position and size of the parent and the dropdown.
 * This methods calculates and returns where the dropdown should be placed
 * taking into account the given preferred positions.
 *
 * @param {HTMLElement} target
 * @param {HTMLElement} element
 * @param {'TOP' | 'BOTTOM' | 'VERTICAL_CENTER'} defaultVerticalPosition
 * @param {'LEFT' | 'RIGHT' | 'HORIZONTAL_CENTER'} defaultHorizontalPosition
 * @param {boolean} stick
 *
 * @returns {['TOP' | 'BOTTOM' | 'VERTICAL_CENTER', 'LEFT' | 'RIGHT' | 'HORIZONTAL_CENTER']} `[vertical, horizontal]`
 */
const getDialogPosition = (target, element, defaultVerticalPosition, defaultHorizontalPosition, stick) => {
  const targetBoundingBox = getBoundingBox(target);

  if (!targetBoundingBox) {
    return [defaultVerticalPosition, defaultHorizontalPosition];
  }

  const {
    bottom: targetBottom,
    top: targetTop,
    left: targetLeft,
    right: targetRight,
    width: targetWidth
  } = targetBoundingBox;

  const [dropdownHeight, dropdownWidth] = getAbsoluteSize(element);

  if (stick) {
    if (dropdownHeight >= getDocumentHeight()) {
      return [POSITIONS.TOP, defaultHorizontalPosition];
    }

    return [defaultVerticalPosition, defaultHorizontalPosition];
  }

  const widthDifference = dropdownWidth - targetWidth;

  const isTriggerInViewport = isElementInViewport(target);

  const fitsToBottom = targetBottom + dropdownHeight < getDocumentHeight();
  const fitsToTop = targetTop - dropdownHeight >= 0;
  const fitsToRight =
    defaultVerticalPosition === POSITIONS.VERTICAL_CENTER
      ? targetRight + dropdownWidth <= getDocumentWidth()
      : targetLeft + dropdownWidth <= getDocumentWidth();
  const fitsToLeft =
    defaultVerticalPosition === POSITIONS.VERTICAL_CENTER
      ? targetLeft - dropdownWidth >= 0
      : targetRight - dropdownWidth >= 0;
  const fitsToRightCentered = targetLeft + (dropdownWidth - widthDifference / 2) <= getDocumentWidth();
  const fitsToLeftCentered = targetRight - (dropdownWidth - widthDifference / 2) >= 0;

  const spaceToBottom = getDocumentHeight() - targetBottom;
  const spaceToTop = targetTop;

  let positionVertical = defaultVerticalPosition;
  if (defaultVerticalPosition === POSITIONS.BOTTOM) {
    positionVertical = fitsToBottom || (!fitsToTop && spaceToBottom >= spaceToTop) ? POSITIONS.BOTTOM : POSITIONS.TOP;
  } else if (defaultVerticalPosition === POSITIONS.TOP) {
    positionVertical = fitsToTop || (!fitsToBottom && spaceToTop >= spaceToBottom) ? POSITIONS.TOP : POSITIONS.BOTTOM;
  } else if (defaultVerticalPosition === POSITIONS.VERTICAL_CENTER) {
    positionVertical = POSITIONS.VERTICAL_CENTER;
  }

  let positionHorizontal = defaultHorizontalPosition;
  if (defaultHorizontalPosition === POSITIONS.LEFT) {
    if (positionVertical !== POSITIONS.VERTICAL_CENTER) {
      positionHorizontal =
        !fitsToLeft && !fitsToRight ? POSITIONS.HORIZONTAL_CENTER : fitsToRight ? POSITIONS.LEFT : POSITIONS.RIGHT;
    } else {
      positionHorizontal = fitsToLeft ? POSITIONS.LEFT : fitsToRight ? POSITIONS.RIGHT : POSITIONS.HORIZONTAL_CENTER;
    }
  } else if (defaultHorizontalPosition === POSITIONS.RIGHT) {
    if (positionVertical !== POSITIONS.VERTICAL_CENTER) {
      positionHorizontal =
        !fitsToLeft && !fitsToRight ? POSITIONS.HORIZONTAL_CENTER : fitsToLeft ? POSITIONS.RIGHT : POSITIONS.LEFT;
    } else {
      positionHorizontal = fitsToRight ? POSITIONS.RIGHT : fitsToLeft ? POSITIONS.LEFT : POSITIONS.HORIZONTAL_CENTER;
    }
  } else if (defaultHorizontalPosition === POSITIONS.HORIZONTAL_CENTER) {
    if (positionVertical !== POSITIONS.VERTICAL_CENTER) {
      positionHorizontal =
        fitsToLeftCentered && fitsToRightCentered
          ? POSITIONS.HORIZONTAL_CENTER
          : fitsToLeft
            ? POSITIONS.RIGHT
            : POSITIONS.LEFT;
    } else {
      positionHorizontal =
        fitsToLeftCentered && fitsToRightCentered
          ? POSITIONS.HORIZONTAL_CENTER
          : fitsToRight
            ? POSITIONS.RIGHT
            : fitsToLeft
              ? POSITIONS.LEFT
              : POSITIONS.HORIZONTAL_CENTER;
    }
  }

  if (!isTriggerInViewport) {
    return [defaultVerticalPosition, positionHorizontal];
  }

  return [positionVertical, positionHorizontal];
};

export default getDialogPosition;
