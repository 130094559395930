import http from '../http';

const form = _ => _.form;
const careers = _ => _.careers;
const none = _ => _;

export const formdata = (file, fields) => {
  const formData = new FormData();

  formData.append('Content-Type', file && file.type);
  Object.keys(fields).forEach(key => formData.append(key, fields[key]));
  formData.append('file', file);

  return formData;
};

const get = (_ = none) => (path = '', configuration = {}) =>
  http
    .then(_)
    .then(h => h.get(path, (configuration && {...configuration}) || {}))
    .then(({data} = {}) => data);

const post = (_ = none) => async (path, body = {}, configuration = {}) =>
  http
    .then(_)
    .then(h => h.post(path, body, (configuration && {...configuration}) || {}))
    .then(({data} = {}) => data);

export default {
  account: name => ({
    count: async () => await get(careers)(`/api/v1/accounts/${name}/jobs/count`),
    departments: async (withOpenJobsOnly = true) =>
      await get(careers)(`/api/v2/accounts/${name}/jobs/departments?all=${!withOpenJobsOnly}`),
    details: async () => await get(careers)(`/api/v1/accounts/${name}`, {params: {full: true}}),
    filters: async () => await get(careers)(`/api/v3/accounts/${name}/jobs/filters`),
    gdpr: async version => await get(careers)(`/api/v1/accounts/${name}/gdpr`, version ? {params: {version}} : {}),
    job: async shortcode => await get(careers)(`/api/v2/accounts/${name}/jobs/${shortcode}`, {withCredentials: true}),
    jobs: async (body = {}, configuration = {}) =>
      await post(careers)(`/api/v3/accounts/${name}/jobs`, body, configuration)
  }),
  apply: shortcode => ({
    withAnswers: async (answers, configuration) =>
      await post(form)(
        `/api/v1/jobs/${shortcode}/apply`,
        {candidate: answers},
        {...configuration, withCredentials: true}
      )
  }),
  attribute: async (shortcode, payload = {}) =>
    await post(careers)(`/api/v1/attribute/${shortcode}`, payload, {withCredentials: true}),
  avatar: async (data, configuration) => await post(form)('/api/v1/avatar', data, {...configuration}),
  cv: shortcode => ({
    autofill: async configuration => await get(form)(`/api/v1/jobs/${shortcode}/autofill`, configuration),
    presigned: async (fieldId, configuration) =>
      await get(form)(`/api/v1/jobs/${shortcode}/form/upload/${fieldId}`, configuration)
  }),
  download: async (url, configuration = {}) => await get()(url, {...configuration, responseType: 'blob'}),
  eeoc: async (id, answers, configuration) => await post(form)(`/api/v1/eeoc/${id}`, answers, configuration),
  form: async shortcode =>
    await http
      .then(form)
      .then(h => h.get(`/api/v1/jobs/${shortcode}/form`))
      .then(({data, headers: {'x-country': geoipcountry} = {}} = {}) => {
        if (data && geoipcountry) {
          data.geoipcountry = geoipcountry;
        }
        return data;
      }),
  survey: {
    submit: async (id, payload, configuration) =>
      await post(careers)(`/api/v1/candidates/surveys/${id}`, payload, configuration),
    view: async id => await get(careers)(`/api/v1/surveys/${id}`)
  },
  upload: ({configuration, data: {fields, url} = {}, file}) => post()(url, formdata(file, fields), configuration)
};
