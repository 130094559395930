import React from 'react';
import isError from 'lodash/isError';
import isString from 'lodash/isString';
import snakeCase from 'lodash/snakeCase';
import {NotFoundError, OfflineError, ServerError, UnknownError} from './errors';

export const parse = error => {
  let _error;
  const status = error && error.response && error.response.status;
  if (status) {
    _error = status;
  } else if (isString(error)) {
    _error = error;
  } else if (isError(error)) {
    _error = error.message;
  }
  return snakeCase(_error);
};

export const HttpError = ({error}) => {
  const _error = snakeCase(parse(error));
  switch (_error) {
    case '500':
    case '502':
    case '503':
    case '504':
      return <ServerError />;
    case 'network_error':
      return <OfflineError />;
    case '404':
      return <NotFoundError />;
    case '400':
    case '401':
    case '402':
    case '403':
    case '405':
    case '408':
    default:
      return <UnknownError />;
  }
};

export default HttpError;
