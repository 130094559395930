import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import getRandomString from 'shared/ui/helpers/getRandomString';
import propsFilter from 'shared/ui/helpers/propsFilter';
import {useEvergreenTranslations} from 'shared/ui/providers/translations';
import symbols from 'shared/ui/symbols';

import IconButton from 'shared/ui/molecules/button/icon';
import TertiaryButton from 'shared/ui/atoms/button/tertiary';
import TextBody from 'shared/ui/atoms/text/body';
import MainBody from 'shared/ui/atoms/text/mainBody';
import RemoveIcon from 'shared/ui/atoms/icon/remove';
import SvgLoader from 'shared/ui/atoms/icon/SVGLoaders/sprite';

import bulb16 from 'assets/svg/bulb16.svg?sprite';

import styles from './styles.scss';

const DEFAULT_FLAVOR = 'informational';
const DEFAULT_ICON = props => <SvgLoader {...props} svg={bulb16} strokeWidth={0} />;

const getTitleID = id => `${id}_alert_title`;
const getMessageID = id => `${id}_alert_message`;

const getFlavorFromProps = ({caution, danger, informational, success, ai}) => {
  const flavors = {caution, danger, informational, success, ai};

  return Object.keys(flavors).filter(key => !!flavors[key])[0] || DEFAULT_FLAVOR;
};

const BUTTON_FLAVOR_MAP = {
  caution: {caution: true},
  danger: {danger: true},
  informational: {informational: true},
  ai: {ai: true},
  success: {} // Default button flavor
};

const renderAction = ({action, onClick, ...restProps}) => {
  const flavorProps = BUTTON_FLAVOR_MAP[getFlavorFromProps(restProps)];
  const metaProps = {'data-role': 'alert-action'};

  if (!action) {
    return null;
  }

  return typeof action === 'string' ? (
    <TertiaryButton {...metaProps} {...flavorProps} onClick={onClick}>
      {action}
    </TertiaryButton>
  ) : React.isValidElement(action) ? (
    symbols.Button.includes(action.type) ? (
      React.cloneElement(action, {...metaProps, ...flavorProps})
    ) : (
      action
    )
  ) : null;
};

const renderTitle = ({id, title}) => {
  const metaProps = {
    id: getTitleID(id),
    'data-role': 'alert-title'
  };

  return title ? (
    typeof title === 'string' ? (
      <MainBody as="h4" strong emphasis {...metaProps}>
        {title}
      </MainBody>
    ) : React.isValidElement(title) ? (
      React.cloneElement(title, metaProps)
    ) : null
  ) : null;
};

const renderMessage = ({id, message}) => {
  const metaProps = {
    id: getMessageID(id),
    'data-role': 'alert-message'
  };

  return message ? (
    typeof message === 'string' ? (
      <TextBody {...metaProps}> {message} </TextBody>
    ) : React.isValidElement(message) ? (
      React.cloneElement(message, metaProps)
    ) : null
  ) : null;
};

const ICON_FLAVOR_MAP = {
  caution: {color: 'orange600', transparent: true},
  danger: {color: 'red500', transparent: true},
  informational: {color: 'blue500', transparent: true},
  success: {color: 'green500', transparent: true},
  ai: {color: 'purple600', transparent: true}
};

const renderFromProps = ({
  id,
  title,
  message,
  action,
  onActionClick,
  onDismiss,
  children,
  texts,
  icon,
  ...restProps
}) => {
  const iconFlavorProps = ICON_FLAVOR_MAP[getFlavorFromProps(restProps)];

  const alertContent = (
    <React.Fragment>
      {renderTitle({id, title})}
      {renderMessage({id, message})}
      {renderAction({action, onClick: onActionClick, ...restProps})}
      {children}
    </React.Fragment>
  );

  const dismissable = typeof onDismiss === 'function';

  const Icon = typeof icon === 'boolean' && icon ? DEFAULT_ICON : icon;

  return (
    <>
      {Icon && (
        <div className={styles['icon-container']}>
          <Icon {...iconFlavorProps} size={16} />
        </div>
      )}
      {dismissable || Icon ? <div data-ui="alert-content">{alertContent}</div> : alertContent}
      {dismissable && (
        <div className={styles['dismiss-container']}>
          <IconButton
            role="button"
            type="button"
            aria-label={texts.dismiss}
            aria-describedby={title ? getTitleID(id) : message ? getMessageID(id) : ''}
            transparent
            small
            onClick={onDismiss}
          >
            <RemoveIcon size="14" transparent />
          </IconButton>
        </div>
      )}
    </>
  );
};

const AlertStatic = ({id = getRandomString(), texts: _texts, ...props}) => {
  const texts = useEvergreenTranslations('alert.static', _texts);

  const dismissable = typeof props.onDismiss === 'function';
  const hasIcon = !!props.icon;

  const flavor = getFlavorFromProps(props);

  const ariaProps = {
    'aria-atomic': true,
    'aria-describedby': props.message ? getMessageID(id) : '',
    'aria-labelledby': props.title ? getTitleID(id) : '',
    'aria-modal': false,
    role: props.action ? 'alertdialog' : 'alert'
  };

  const dataProps = propsFilter(props).dataAttributes().styles().getFiltered();

  return (
    <section
      {...ariaProps}
      {...dataProps}
      className={clsx(
        {[styles['alert-static']]: true},
        {[styles[flavor]]: true},
        {[styles.dismissable]: dismissable, [styles['with-icon']]: hasIcon},
        dataProps.className
      )}
      id={id}
    >
      {renderFromProps({id, dismissable, texts, ...props})}
    </section>
  );
};

AlertStatic.displayName = 'Alert.Static';

AlertStatic[symbols.Alert.Static] = true;

AlertStatic.propTypes = {
  /** Displays the alert according to DSTFs `caution` styled guidelines */
  caution: PropTypes.bool,
  /** Displays the alert according to DSTFs `danger` styled guidelines */
  danger: PropTypes.bool,
  /** Displays the alert according to DSTFs `success` styled guidelines */
  success: PropTypes.bool,
  /** Displays the alert according to DSTFs `informational` styled guidelines */
  informational: PropTypes.bool,
  /** Displays the alert according to DSTFs `ai` styled guidelines */
  ai: PropTypes.bool,
  /** The main message body of the alert. Can be either a string or react element. */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** The title of the alert. Can be either a string or react element. */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** The action button of the alert. If passed a string creates a Tertiary button */
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** When `action` prop is string, is being triggered when user clicks on the action button */
  onActionClick: PropTypes.func,
  /** Callback used when the dismiss button is clicked. Controls whether it will be dismissable or not. */
  onDismiss: PropTypes.func,
  /** An object with locales for the component */
  texts: PropTypes.object
};

export default AlertStatic;
