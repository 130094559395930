import React from 'react';
import Skeleton, {SkeletonProps} from '../component';

export type SkeletonBodyProps = Omit<SkeletonProps, 'width' | 'height' | 'darker'> & {
  link?: boolean;
  width?: SkeletonProps['width'];
  height?: SkeletonProps['height'];
};

const SkeletonBody = ({width = '80px', link, height = '16px', ...props}: SkeletonBodyProps) => {
  return <Skeleton {...props} width={width} height={height} darker={link} />;
};

SkeletonBody.displayName = 'SkeletonBody';

export default SkeletonBody;
