import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import mapPropsToStylenames from 'shared/ui/helpers/mapPropsToStyleNames';
import withTextStyles from 'shared/ui/helpers/hocs/withTextStyles';
import {SkeletonBody} from 'shared/ui/atoms/skeleton';

import styles from './styles.scss';

const Base = withTextStyles(
  ({
    children,
    success,
    caution,
    danger,
    secondary,
    primary,
    emphasis,
    muted,
    neutral,
    transparent,
    skeleton,
    skeletonWidth,
    skeletonHeight,
    skeletonProps,
    defaultFlavor = 'primary',
    as: TextKind = 'span',
    ...props
  }) => {
    const flavorClassName = mapPropsToStylenames({
      success,
      caution,
      danger,
      secondary: secondary || neutral || muted,
      primary: primary || emphasis,
      transparent,
      default: defaultFlavor
    });

    if (skeleton) {
      return <SkeletonBody width={skeletonWidth} height={skeletonHeight} data-ui="skeleton-text" {...skeletonProps} />;
    }

    return (
      <TextKind {...props} className={clsx(styles[flavorClassName], props.className)}>
        {children}
      </TextKind>
    );
  }
);

Base.propTypes = {
  ...Base.propTypes,
  as: PropTypes.string,
  children: PropTypes.node,
  success: PropTypes.bool,
  caution: PropTypes.bool,
  danger: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  /**
   * @deprecated use 'primary' instead
   */
  emphasis: PropTypes.bool,
  /**
   * @deprecated use 'secondary' instead
   */
  muted: PropTypes.bool,
  /**
   * @deprecated use 'secondary' instead
   */
  neutral: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string,
  defaultFlavor: PropTypes.oneOf(['success', 'caution', 'danger', 'primary', 'secondary']),
  skeleton: PropTypes.bool
};

Base.displayName = 'Text.Base';

export default Base;
